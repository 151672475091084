import React, { useState, useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { teelColor } from '../utils/config';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import EmailIcon from '@mui/icons-material/Email';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import SettingsIcon from '@mui/icons-material/Settings';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import { UserContext } from '../@core/Provider/UserContext';

const MyAppBar = ({ onMenuClick }) => {
  const navigate = useNavigate();
  const isLargeScreen = useMediaQuery('(min-width:600px)');
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('MyAppBar must be used within a UserProvider');
  }

  const { userInfo, setUserInfo } = context;

  const handleLogoClick = () => {
    navigate('/');
  };

  const handleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('tokenlpa');
    setUserInfo(null);
    window.location.href = '/login';
  };

  return (
    <AppBar position="fixed" sx={{ background: teelColor }}>
      <Toolbar>
        {!isLargeScreen && (
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={onMenuClick}>
            <MenuIcon />
          </IconButton>
        )}
        
        <Typography variant="h6" sx={{ flexGrow: 1, cursor: 'pointer' }} onClick={handleLogoClick}>
        {userInfo.username}
        </Typography>

        <IconButton color="inherit" aria-label="toggle night mode">
          <NightsStayIcon />
        </IconButton>

        <IconButton color="inherit" aria-label="show notifications">
          <NotificationsIcon />
        </IconButton>

        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          variant="dot"
          sx={{
            '& .MuiBadge-dot': {
              backgroundColor: 'green',
              border: '2px solid white',
            },
            ml: 2,
            cursor: 'pointer'
          }}
          onClick={handleProfileClick}
        >
          <Avatar alt={userInfo?.firstname || 'profile'}
                src={userInfo?.getUserAvatar()}/>
        </Badge>

        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            sx: {
              width: '250px',
              mt: 1.5,
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <Box sx={{ p: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Avatar sx={{ width: 40, height: 40, mr: 2 }}
               alt={userInfo?.firstname || 'profile'}
                src={userInfo?.getUserAvatar()} />
              <Box>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  {userInfo?.firstname || 'User'}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                {userInfo?.getUserRole()}
                </Typography>
              </Box>
            </Box>
          </Box>
          
          <Divider />
          
          <MenuItem onClick={() => navigate('/UserProfile')}>
            <ListItemIcon>
              <PersonOutlineIcon />
            </ListItemIcon>
            Profile
          </MenuItem>
          
          {/* <MenuItem onClick={() => navigate('/inbox')}>
            <ListItemIcon>
              <EmailIcon />
            </ListItemIcon>
            Inbox
          </MenuItem>
          
          <MenuItem onClick={() => navigate('/chat')}>
            <ListItemIcon>
              <ChatBubbleOutlineIcon />
            </ListItemIcon>
            Chat
          </MenuItem>
          
          <Divider />
          
          <MenuItem onClick={() => navigate('/settings')}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            Settings
          </MenuItem>
          
          <MenuItem onClick={() => navigate('/pricing')}>
            <ListItemIcon>
              <AttachMoneyIcon />
            </ListItemIcon>
            Pricing
          </MenuItem>
          
          <MenuItem onClick={() => navigate('/faq')}>
            <ListItemIcon>
              <HelpOutlineIcon />
            </ListItemIcon>
            FAQ
          </MenuItem> */}
          
          <Divider />
          
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default MyAppBar;

