import React, { useState, useEffect, useContext } from 'react';
import {
  Checkbox,
  FormControlLabel,
  CardContent,
  Typography,
  Box,
  Link,
  Container,
  InputAdornment,
  IconButton,
  CircularProgress,
  Grid2
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import CustomTextField from '../../Components/formComponent/CustomTextField';
import CustomButton from '../../Components/formComponent/CustomButton';
import { grayColor, teelColor } from '../../utils/config';
import LoginController from '../../Controllers/LoginController';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserModel from '../../models/UserModel';
import { UserContext } from '../../@core/Provider/UserContext';

function LoginPage() {
  const [values, setValues] = useState({ email: '', password: '' });
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ email: '', password: '' });
  const { setUserInfo } = useContext(UserContext);

  useEffect(() => {
    // Demander la permission pour afficher des notifications
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('Permission for notifications granted.');
      }
    });
  }, []);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => setShowPassword((prev) => !prev);

  const validate = () => {
    let isValid = true;
    const errors = { email: '', password: '' };

    if (!values.email) {
      errors.email = 'Email is required';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = 'Email is invalid';
      isValid = false;
    }

    if (!values.password) {
      errors.password = 'Password is required';
      isValid = false;
    } else if (values.password.length < 6) {
      errors.password = 'Password should be at least 6 characters';
      isValid = false;
    }

    setError(errors);
    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        const response = await LoginController.login({
          email: values.email,
          password: values.password,
        });

        localStorage.setItem('tokenlpa', response.data.token);
        toast.success(response.data.message);
        showNotification(response.data.message);

        const profileResponse = await LoginController.getProfile();

        if (profileResponse.status === 200) {
          const data = profileResponse.data;
          const user = new UserModel(data.user);
          setUserInfo(user);
          setTimeout(() => {
            window.location.href = '/';
          }, 2000);
        } else {
          console.error('Failed to fetch profile:', profileResponse);
        }
      } catch (error) {
        const errorMessage = error.response?.data?.message || 'Connection error, please try again.';
        toast.error(errorMessage);
        showNotification(errorMessage, true);
        setError({ email: '', password: errorMessage });
      } finally {
        setLoading(false);
      }
    }
  };

  const showNotification = (message, isError = false) => {
    if (Notification.permission === 'granted') {
      new Notification(isError ? 'Erreur' : 'Succès', {
        body: message,
        icon: 'https://via.placeholder.com/48', // Remplacez par votre icône
      });
    }
  };

  return (
    <Container maxWidth="xs">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <CardContent
          sx={{
            boxShadow: 3,
            borderRadius: 2,
            padding: 4,
            width: '100%',
          }}
        >
          <Typography variant="h4" gutterBottom color={teelColor}>
            LPA
          </Typography>
          <Typography variant="h6" gutterBottom color={teelColor}>
            Welcome to LPA! 👋
          </Typography>
          <Typography variant="body2" color="textSecondary" align="center" gutterBottom>
            Please sign in to your account and start the adventure
          </Typography>

          <form noValidate autoComplete="off" onSubmit={handleSubmit} style={{ width: '100%', marginTop: '16px' }}>
            <CustomTextField
              label="Email"
              name="email"
              value={values.email}
              onChange={handleChange('email')}
              error={!!error.email}
              helperText={error.email}
              autoComplete="email"
              autoFocus
              margin="normal"
              required
            />

            <CustomTextField
              label="Password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              value={values.password}
              onChange={handleChange('password')}
              error={!!error.password}
              helperText={error.password}
              autoComplete="current-password"
              margin="normal"
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <FormControlLabel
              control={<Checkbox value="remember" sx={{ color: teelColor }} />}
              label="Remember Me"
              sx={{ color: teelColor }}
            />

            <CustomButton type="submit" sx={{ mt: 3, mb: 2 }} disabled={loading}>
              {loading ? <CircularProgress size={24} /> : 'LOGIN'}
            </CustomButton>

            <ToastContainer
              position="top-center"
              autoClose={2000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              style={{ marginTop: '50px', height: '50px' }}
            />

            <Box display="flex" justifyContent="space-between">
              <Link href="#" variant="body2" color={teelColor}>
                Forgot Password?
              </Link>
              <Link href="#" variant="body2" color={teelColor}>
                Create an account
              </Link>
            </Box>
            
          </form>
          {/* admin : devops@gmail.com
           

admin : 5DwtVaPuAVbt */}
          <Grid2 item sx={{ marginTop:6 }} xs={12} sm={6} md={4}>
          <Box display="flex" justifyContent="space-between">
              
              <Typography  variant="body2" color={grayColor}>
                email: devadmin@gmail.com
              </Typography>
              <Typography variant="body2" color={grayColor}>
                password: 8KbD30XXuD3K
              </Typography>

            </Box>
            <Box display="flex" justifyContent="space-between">
              
            <Typography  variant="body2" color={grayColor}>
              admin : devops@gmail.com
              </Typography>
              <Typography variant="body2" color={grayColor}>
              password :5DwtVaPuAVbt
              </Typography>
            </Box>
          </Grid2>
          
        </CardContent>
      </Box>
    </Container>
  );
}

export default LoginPage;


// import React, { useState,useEffect,useContext } from 'react';
// import {
//   Checkbox,
//   FormControlLabel,
//   CardContent,
//   Typography,
//   Box,
//   Link,
//   Container,
//   InputAdornment,
//   IconButton,
//   CircularProgress,
// } from '@mui/material';
// import { Visibility, VisibilityOff } from '@mui/icons-material';
// import CustomTextField from '../../Components/formComponent/CustomTextField';
// import CustomButton from '../../Components/formComponent/CustomButton';
// import { teelColor } from '../../utils/config';
// import LoginController from '../../Controllers/LoginController';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import UserModel from '../../models/UserModel';
// import { UserContext } from '../../@core/Provider/UserContext';


// function LoginPage() {
//   const [values, setValues] = useState({ email: '', password: '' });
//   const [showPassword, setShowPassword] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState({ email: '', password: '' });
//   const { setUserInfo } = useContext(UserContext)


//   useEffect(() => {
//     // Demander la permission pour afficher des notifications
//     Notification.requestPermission().then((permission) => {
//       if (permission === 'granted') {
//         console.log('Permission for notifications granted.');
//       }
//     });
//   }, []);


//   const handleChange = (prop) => (event) => {
//     setValues({ ...values, [prop]: event.target.value });
//   };

//   const handleClickShowPassword = () => setShowPassword((prev) => !prev);

//   const validate = () => {
//     let isValid = true;
//     const errors = { email: '', password: '' };

//     if (!values.email) {
//       errors.email = 'Email is required';
//       isValid = false;
//     } else if (!/\S+@\S+\.\S+/.test(values.email)) {
//       errors.email = 'Email is invalid';
//       isValid = false;
//     }

//     if (!values.password) {
//       errors.password = 'Password is required';
//       isValid = false;
//     } else if (values.password.length < 6) {
//       errors.password = 'Password should be at least 6 characters';
//       isValid = false;
//     }

//     setError(errors);
//     return isValid;
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     if (validate()) {
//       setLoading(true);
//       try {
//         const response = await LoginController.login({
//           email: values.email,
//           password: values.password,
//         });
        
//         localStorage.setItem('tokenlpa', response.data.token);
//         toast.success(response.data.message);
//         showNotification(response.data.message);

        
//         const profileResponse = await LoginController.getProfile();
        
//         if (profileResponse.status === 200) {
//           const data = profileResponse.data;
//           console.log('Profile Data:', data);
//           const user = new UserModel(profileResponse.data.user);
//           console.log('user recuperer',user)
//           setUserInfo(user)
//           setTimeout(() => {
//             window.location.href = '/';
//           }, 2000);
//         } else {
//           console.error('Failed to fetch profile:', profileResponse);
//         }

//       } catch (error) {
//         const errorMessage = error.response?.data?.message || 'Connection error, please try again.';
//         toast.error(errorMessage);
//         showNotification(errorMessage, true);
//         setError({ email: '', password: errorMessage });
//       } finally {
//         setLoading(false);
//       }
//     }
//   };

//   const showNotification = (message, isError = false) => {
//     if (Notification.permission === 'granted') {
//       new Notification(isError ? 'Erreur' : 'Succès', {
//         body: message,
//         icon: 'https://via.placeholder.com/48', // Remplacez par votre icône
//       });
//     }
//   };

//   return (
//     <Container maxWidth="xs">
//       <Box
//         sx={{
//           display: 'flex',
//           flexDirection: 'column',
//           alignItems: 'center',
//           justifyContent: 'center',
//           height: '100vh',
//         }}
//       >
//         <CardContent
//           sx={{
//             boxShadow: 3,
//             borderRadius: 2,
//             padding: 4,
//             width: '100%',
//           }}
//         >
//           <Typography variant="h4" gutterBottom color={teelColor}>
//             LPA
//           </Typography>
//           <Typography variant="h6" gutterBottom color={teelColor}>
//             Welcome to LPA! 👋
//           </Typography>
//           <Typography variant="body2" color="textSecondary" align="center" gutterBottom>
//             Please sign in to your account and start the adventure
//           </Typography>

//           <form noValidate autoComplete="off" onSubmit={handleSubmit} style={{ width: '100%', marginTop: '16px' }}>
//             <CustomTextField
//               label="Email"
//               name="email"
//               value={values.email}
//               onChange={handleChange('email')}
//               error={!!error.email}
//               helperText={error.email}
//               autoComplete="email"
//               autoFocus
//               margin="normal"
//               required
//             />

//             <CustomTextField
//               label="Password"
//               name="password"
//               type={showPassword ? 'text' : 'password'}
//               value={values.password}
//               onChange={handleChange('password')}
//               error={!!error.password}
//               helperText={error.password}
//               autoComplete="current-password"
//               margin="normal"
//               required
//               InputProps={{
//                 endAdornment: (
//                   <InputAdornment position="end">
//                     <IconButton
//                       aria-label="toggle password visibility"
//                       onClick={handleClickShowPassword}
//                       edge="end"
//                     >
//                       {showPassword ? <VisibilityOff /> : <Visibility />}
//                     </IconButton>
//                   </InputAdornment>
//                 ),
//               }}
//             />

//             <FormControlLabel
//               control={<Checkbox value="remember" sx={{ color: teelColor }} />}
//               label="Remember Me"
//               sx={{ color: teelColor }}
//             />

//             <CustomButton type="submit" sx={{ mt: 3, mb: 2 }} disabled={loading}>
//               {loading ? <CircularProgress size={24} /> : 'LOGIN'}
//             </CustomButton>

//             <ToastContainer
//               position="top-center"
//               autoClose={2000}
//               hideProgressBar={false}
//               newestOnTop={false}
//               closeOnClick
//               rtl={false}
//               pauseOnFocusLoss
//               draggable
//               pauseOnHover
//               style={{ marginTop: '50px', height: '50px' }}
//             />

//             <Box display="flex" justifyContent="space-between">
//               <Link href="#" variant="body2" color={teelColor}>
//                 Forgot Password?
//               </Link>
//               <Link href="#" variant="body2" color={teelColor}>
//                 Create an account
//               </Link>
//             </Box>
//           </form>
//         </CardContent>
//       </Box>
//     </Container>
//   );
// }

// export default LoginPage;



// import React, { useState } from 'react';
// import {
//   Checkbox,
//   FormControlLabel,
//   CardContent,
//   Typography,
//   Box,
//   Link,
//   Container,
//   InputAdornment,
//   IconButton,
//   CircularProgress,
// } from '@mui/material';
// import { Visibility, VisibilityOff } from '@mui/icons-material';
// import CustomTextField from '../../Components/formComponent/CustomTextField';
// import CustomButton from '../../Components/formComponent/CustomButton';
// import { teelColor } from '../../utils/config';
// import LoginController from '../../Controllers/LoginController';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// function LoginPage() {
//   const [values, setValues] = useState({ email: '', password: '' });
//   const [showPassword, setShowPassword] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState({ email: '', password: '' });

//   const handleChange = (prop) => (event) => {
//     setValues({ ...values, [prop]: event.target.value });
//   };

//   const handleClickShowPassword = () => setShowPassword((prev) => !prev);

//   const validate = () => {
//     let isValid = true;
//     const errors = { email: '', password: '' };

//     if (!values.email) {
//       errors.email = 'Email is required';
//       isValid = false;
//     } else if (!/\S+@\S+\.\S+/.test(values.email)) {
//       errors.email = 'Email is invalid';
//       isValid = false;
//     }

//     if (!values.password) {
//       errors.password = 'Password is required';
//       isValid = false;
//     } else if (values.password.length < 6) {
//       errors.password = 'Password should be at least 6 characters';
//       isValid = false;
//     }

//     setError(errors);
//     return isValid;
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     if (validate()) {
//       setLoading(true);
//       try {
//         const response = await LoginController.login({
//           email: values.email,
//           password: values.password,
//         });
        
//         localStorage.setItem('tokenlpa', response.data.token);
//         toast.success(response.data.message);
        
//         const profileResponse = await LoginController.getProfile();
//         console.log('Profile Response:', profileResponse); // Log the entire response
        
//         // Assurez-vous que le statut est bien 200
//         if (profileResponse.status === 200) {
//           const data = profileResponse.data;
//           console.log('Profile Data:', data);

//           // Redirigez après un délai
//           setTimeout(() => {
//             window.location.href = '/';
//           }, 2000);
//         } else {
//           console.error('Failed to fetch profile:', profileResponse);
//         }

//       } catch (error) {
//         const errorMessage = error.response?.data?.message || 'Connection error, please try again.';
//         toast.error(errorMessage);
//         setError({ email: '', password: errorMessage });
//       } finally {
//         setLoading(false); // S'assurer que le chargement est arrêté dans tous les cas
//       }
//     }
//   };

//   return (
//     <Container maxWidth="xs">
//       <Box
//         sx={{
//           display: 'flex',
//           flexDirection: 'column',
//           alignItems: 'center',
//           justifyContent: 'center',
//           height: '100vh',
//         }}
//       >
//         <CardContent
//           sx={{
//             boxShadow: 3,
//             borderRadius: 2,
//             padding: 4,
//             width: '100%',
//           }}
//         >
//           <Typography variant="h4" gutterBottom color={teelColor}>
//             LPA
//           </Typography>
//           <Typography variant="h6" gutterBottom color={teelColor}>
//             Welcome to LPA! 👋
//           </Typography>
//           <Typography variant="body2" color="textSecondary" align="center" gutterBottom>
//             Please sign in to your account and start the adventure
//           </Typography>

//           <form noValidate autoComplete="off" onSubmit={handleSubmit} style={{ width: '100%', marginTop: '16px' }}>
//             <CustomTextField
//               label="Email"
//               name="email"
//               value={values.email}
//               onChange={handleChange('email')}
//               error={!!error.email}
//               helperText={error.email}
//               autoComplete="email"
//               autoFocus
//               margin="normal"
//               required
//             />

//             <CustomTextField
//               label="Password"
//               name="password"
//               type={showPassword ? 'text' : 'password'}
//               value={values.password}
//               onChange={handleChange('password')}
//               error={!!error.password}
//               helperText={error.password}
//               autoComplete="current-password"
//               margin="normal"
//               required
//               InputProps={{
//                 endAdornment: (
//                   <InputAdornment position="end">
//                     <IconButton
//                       aria-label="toggle password visibility"
//                       onClick={handleClickShowPassword}
//                       edge="end"
//                     >
//                       {showPassword ? <VisibilityOff /> : <Visibility />}
//                     </IconButton>
//                   </InputAdornment>
//                 ),
//               }}
//             />

//             <FormControlLabel
//               control={<Checkbox value="remember" sx={{ color: teelColor }} />}
//               label="Remember Me"
//               sx={{ color: teelColor }}
//             />

//             <CustomButton type="submit" sx={{ mt: 3, mb: 2 }} disabled={loading}>
//               {loading ? <CircularProgress size={24} /> : 'LOGIN'}
//             </CustomButton>

//             <ToastContainer
//               position="top-center"
//               autoClose={2000}
//               hideProgressBar={false}
//               newestOnTop={false}
//               closeOnClick
//               rtl={false}
//               pauseOnFocusLoss
//               draggable
//               pauseOnHover
//               style={{ marginTop: '50px', height: '50px' }}
//             />

//             <Box display="flex" justifyContent="space-between">
//               <Link href="#" variant="body2" color={teelColor}>
//                 Forgot Password?
//               </Link>
//               <Link href="#" variant="body2" color={teelColor}>
//                 Create an account
//               </Link>
//             </Box>
//           </form>
//         </CardContent>
//       </Box>
//     </Container>
//   );
// }

// export default LoginPage;


// import React, { useState } from 'react';
// import {
//   Checkbox,
//   FormControlLabel,
//   CardContent,
//   Typography,
//   Box,
//   Link,
//   Container,
//   InputAdornment,
//   IconButton,
//   CircularProgress,
// } from '@mui/material';
// import { Visibility, VisibilityOff } from '@mui/icons-material';
// import CustomTextField from '../../Components/formComponent/CustomTextField';
// import CustomButton from '../../Components/formComponent/CustomButton';
// import { teelColor } from '../../utils/config';
// import LoginController from '../../Controllers/LoginController';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// function LoginPage() {
//   const [values, setValues] = useState({ email: '', password: '' });
//   const [showPassword, setShowPassword] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState({ email: '', password: '' });

//   const handleChange = (prop) => (event) => {
//     setValues({ ...values, [prop]: event.target.value });
//   };

//   const handleClickShowPassword = () => setShowPassword((prev) => !prev);

//   const validate = () => {
//     let isValid = true;
//     const errors = { email: '', password: '' };

//     if (!values.email) {
//       errors.email = 'Email is required';
//       isValid = false;
//     } else if (!/\S+@\S+\.\S+/.test(values.email)) {
//       errors.email = 'Email is invalid';
//       isValid = false;
//     }

//     if (!values.password) {
//       errors.password = 'Password is required';
//       isValid = false;
//     } else if (values.password.length < 6) {
//       errors.password = 'Password should be at least 6 characters';
//       isValid = false;
//     }

//     setError(errors);
//     return isValid;
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     if (validate()) {
//       setLoading(true);
//       try {
//         const response = await LoginController.login({
//           email: values.email,
//           password: values.password,
//         });
        
//         localStorage.setItem('tokenlpa', response.data.token);
//         toast.success(response.data.message);
        
        
//         const profileResponse = await LoginController.getProfile();
//         const data=profileResponse.data
//        console.log('dfgfff',data)
//        if(profileResponse.status===200){
//         setTimeout(() => {
//           window.location.href = '/';
//         }, 5000);
//        }

       
//       setLoading(false);
//       } catch (error) {
//         setLoading(false);
//         const errorMessage = error.response?.data?.message || 'Connection error, please try again.';
//         toast.error(errorMessage);
//         setError({ email: '', password: errorMessage });
//       }
//     }
//   };

//   return (
//     <Container maxWidth="xs">
//       <Box
//         sx={{
//           display: 'flex',
//           flexDirection: 'column',
//           alignItems: 'center',
//           justifyContent: 'center',
//           height: '100vh',
//         }}
//       >
//         <CardContent
//           sx={{
//             boxShadow: 3,
//             borderRadius: 2,
//             padding: 4,
//             width: '100%',
//           }}
//         >
//           <Typography variant="h4" gutterBottom color={teelColor}>
//             LPA
//           </Typography>
//           <Typography variant="h6" gutterBottom color={teelColor}>
//             Welcome to LPA! 👋
//           </Typography>
//           <Typography variant="body2" color="textSecondary" align="center" gutterBottom>
//             Please sign in to your account and start the adventure
//           </Typography>

//           <form noValidate autoComplete="off" onSubmit={handleSubmit} style={{ width: '100%', marginTop: '16px' }}>
//             <CustomTextField
//               label="Email"
//               name="email"
//               value={values.email}
//               onChange={handleChange('email')}
//               error={!!error.email}
//               helperText={error.email}
//               autoComplete="email"
//               autoFocus
//               margin="normal"
//               required
//             />

//             <CustomTextField
//               label="Password"
//               name="password"
//               type={showPassword ? 'text' : 'password'}
//               value={values.password}
//               onChange={handleChange('password')}
//               error={!!error.password}
//               helperText={error.password}
//               autoComplete="current-password"
//               margin="normal"
//               required
//               InputProps={{
//                 endAdornment: (
//                   <InputAdornment position="end">
//                     <IconButton
//                       aria-label="toggle password visibility"
//                       onClick={handleClickShowPassword}
//                       edge="end"
//                     >
//                       {showPassword ? <VisibilityOff /> : <Visibility />}
//                     </IconButton>
//                   </InputAdornment>
//                 ),
//               }}
//             />

//             <FormControlLabel
//               control={<Checkbox value="remember" sx={{ color: teelColor }} />}
//               label="Remember Me"
//               sx={{ color: teelColor }}
//             />

//             <CustomButton type="submit" sx={{ mt: 3, mb: 2 }} disabled={loading}>
//               {loading ? <CircularProgress size={24} /> : 'LOGIN'}
//             </CustomButton>

//             <ToastContainer
//               position="top-center"
//               autoClose={5000}
//               hideProgressBar={false}
//               newestOnTop={false}
//               closeOnClick
//               rtl={false}
//               pauseOnFocusLoss
//               draggable
//               pauseOnHover
//             />

//             <Box display="flex" justifyContent="space-between">
//               <Link href="#" variant="body2" color={teelColor}>
//                 Forgot Password?
//               </Link>
//               <Link href="#" variant="body2" color={teelColor}>
//                 Create an account
//               </Link>
//             </Box>
//           </form>
//         </CardContent>
//       </Box>
//     </Container>
//   );
// }

// export default LoginPage;



// // src/pages/LoginPage.js
// import React, { useState } from 'react';
// import {
//   Checkbox,
//   FormControlLabel,
//   CardContent,
//   Typography,
//   Box,
//   Link,
//   Container,
//   InputAdornment,
//   IconButton,
//   CircularProgress,
// } from '@mui/material';
// import { Visibility, VisibilityOff } from '@mui/icons-material';
// import CustomTextField from '../../Components/formComponent/CustomTextField';
// import CustomButton from '../../Components/formComponent/CustomButton';
// import { teelColor } from '../../utils/config';
// import LoginController from '../../Controllers/LoginController';

// import { ToastContainer, toast } from 'react-toastify'
// import 'react-toastify/dist/ReactToastify.css'

// function LoginPage() {
//   const [values, setValues] = useState({ email: '', password: '' });
//   const [showPassword, setShowPassword] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState({ email: '', password: '' });

//   const handleChange = (prop) => (event) => {
//     setValues({ ...values, [prop]: event.target.value });
//   };

//   const handleClickShowPassword = () => setShowPassword(!showPassword);

//   const validate = () => {
//     let isValid = true;
//     const errors = { email: '', password: '' };

//     if (!values.email) {
//       errors.email = 'Email is required';
//       isValid = false;
//     } else if (!/\S+@\S+\.\S+/.test(values.email)) {
//       errors.email = 'Email is invalid';
//       isValid = false;
//     }

//     if (!values.password) {
//       errors.password = 'Password is required';
//       isValid = false;
//     } else if (values.password.length < 6) {
//       errors.password = 'Password should be at least 6 characters';
//       isValid = false;
//     }

//     setError(errors);
//     return isValid;
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     if (validate()) {
//       setLoading(true);
//       try {
//         const response = await LoginController.login({
//           email: values.email,
//           password: values.password,
//         });
//         localStorage.setItem('tokenlpa', response.data.token);
        
//         toast.success(response.data.message)
        
//         const profileResponse = await LoginController.getProfile();
//         localStorage.setItem('profile', JSON.stringify(profileResponse.data));
        
//         setLoading(false);
        
//       } catch (error) {
//         setLoading(false);
//         const errorMessage =
//           error.response?.data?.message || 'Connection error, please try again.';
//           toast.error(errorMessage)
//         setError({ email: '', password: errorMessage });
//       }
//     }
//   };

//   return (
//     <Container maxWidth="xs">
//       <Box
//         sx={{
//           display: 'flex',
//           flexDirection: 'column',
//           alignItems: 'center',
//           justifyContent: 'center',
//           height: '100vh',
//         }}
//       >
//         <CardContent
//           sx={{
//             boxShadow: 3,
//             borderRadius: 2,
//             padding: 4,
//             width: '100%',
//           }}
//         >
//           <Typography variant="h4" gutterBottom color={teelColor}>
//             LPA
//           </Typography>
//           <Typography variant="h6" gutterBottom color={teelColor}>
//             Welcome to LPA! 👋
//           </Typography>
//           <Typography variant="body2" color="textSecondary" align="center" gutterBottom>
//             Please sign in to your account and start the adventure
//           </Typography>

//           <form noValidate autoComplete="off" onSubmit={handleSubmit} style={{ width: '100%', marginTop: '16px' }}>
//             <CustomTextField
//               label="Email"
//               name="email"
//               value={values.email}
//               onChange={handleChange('email')}
//               error={!!error.email}
//               helperText={error.email}
//               autoComplete="email"
//               autoFocus
//               margin="normal"
//               required
//             />

//             <CustomTextField
//               label="Password"
//               name="password"
//               type={showPassword ? 'text' : 'password'}
//               value={values.password}
//               onChange={handleChange('password')}
//               error={!!error.password}
//               helperText={error.password}
//               autoComplete="current-password"
//               margin="normal"
//               required
//               InputProps={{
//                 endAdornment: (
//                   <InputAdornment position="end">
//                     <IconButton
//                       aria-label="toggle password visibility"
//                       onClick={handleClickShowPassword}
//                       edge="end"
//                     >
//                       {showPassword ? <VisibilityOff /> : <Visibility />}
//                     </IconButton>
//                   </InputAdornment>
//                 ),
//               }}
//             />

//             <FormControlLabel
//               control={<Checkbox value="remember" sx={{ color: teelColor }} />}
//               label="Remember Me"
//               sx={{ color: teelColor }}
//             />

//             <CustomButton type="submit" sx={{ mt: 3, mb: 2 }} disabled={loading}>
//               {loading ? <CircularProgress size={24} /> : 'LOGIN'}
//             </CustomButton>

//             <ToastContainer
//               position="top-center"
//               autoClose={5000}
//               hideProgressBar={false}
//               newestOnTop={false}
//               closeOnClick
//               rtl={false}
//               pauseOnFocusLoss
//               draggable
//               pauseOnHover
//             />

//             <Box display="flex" justifyContent="space-between">
//               <Link href="#" variant="body2" color={teelColor}>
//                 Forgot Password?
//               </Link>
//               <Link href="#" variant="body2" color={teelColor}>
//                 Create an account
//               </Link>
//             </Box>
//           </form>
//         </CardContent>
//       </Box>
//     </Container>
//   );
// }

// export default LoginPage;
