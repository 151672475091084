// ** MUI Imports
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// ** Demo Components Imports
import { teelColor } from '../../../utils/config';
import EmployeeListData from '../../tables/employerlisteContent';

const UserEmployer = () => {

  
  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        
      </Grid>
      <Grid item xs={12}>
        <EmployeeListData></EmployeeListData>
      </Grid>
    </Grid>
  );
};

export default UserEmployer;
