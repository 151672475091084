import { postResource, getResource, deleteResource } from "../utils/configmethode";
const VendorController = {
  addProduct: (data) => postResource("api/add/vendor/porduct", data),
  getProduct: (token) => getResource("api/get/vendor/products", token),
  updateProduct: (productId, data) => postResource(`api/update/vendor/products/${productId}`, data),
  deleteProduct: (productId,) => deleteResource(`api/destroy/Product/${productId}`),
  getAllVendor: (token) => getResource('api/get/VendorList', token),
  getTheVendororders: (token) => getResource('api/get/VendorOrders', token),
  validetedorder: (data) => postResource('api/Vendor/validateOrder', data),
  getproductcategory: (token) => getResource('api/get/category/foradmin', token),
  getTestecategorie: (token) => getResource('api/get/Category', token),

  // function pour les admin

  getallvendororder: (token) => getResource('api/getallvendororder', token),
  rhvalidatedcustomerord: (data) => postResource('api/valided/customer/order', data),
  Updatepassword:(data)=>postResource('api/update/User/Password',data),
  updateProfile:(data)=>postResource('api/update/Profile',data),
  

};

export default VendorController;
