import { postResource, getResource, deleteResource } from "../utils/configmethode"
import AddAgency from "../views/pages/AddOthersviews/indexAgence"

const EmployeController = {
  getAllEmployee: (token) => getResource('api/get/employee/all', token),
  fetchvendorproduct: (token) => getResource('api/fetchAll/Vendor/Products'),
  //admin
  AdminupdateCustomerinfo: (data) => postResource('api/Admin/update/Customer/info', data),
  Adminupdatevendorinfo: (data) => postResource('api/Admin/update/vendor/info', data),
  AdmingetCutomerPaiement: (token) => getResource('api/getCutomerPaiement', token),
  getagenceinfo: (token) => getResource('api/get/All/Agences/Info', token),
  AddAgency: (data) => postResource('api/createAgence', data),
  editAgence: (data) => postResource('api/editAgence', data),
  DeleteAgency: (data) => postResource('api/deleteAgence', data),
  updateCategory: (data) => postResource('api/update/Category', data),
  destroyCategory: (data) => postResource('api/destroy/Category', data),
  addvendorcategory: (data) => postResource('api/add/vendor/category', data),

}

export default EmployeController
