import React, { useState, useEffect, useContext } from 'react';
import { Container, Typography, Box, TextField, Button, Avatar, Grid, Badge, Snackbar, Alert, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Paper } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import { teelColor, whiteColor, dashcolor,iconColor } from '../../../utils/config';
import CustomButton from '../../../Components/formComponent/CustomButton';
import CustomCancelButton from '../../../Components/formComponent/CustomCancelButton';
import CustomTextField from '../../../Components/formComponent/CustomTextField';
import { UserContext } from '../../../@core/Provider/UserContext';
import VendorController from '../../../Controllers/VendorControllers';
import CircularProgress from '@mui/material/CircularProgress';


// Importation du modèle UserModel
import UserModel from '../../../models/UserModel';

// CSS Animation for pop effect
import { keyframes } from '@mui/system';

const popAnimation = keyframes`
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const UserProfile = () => {
  const { userInfo } = useContext(UserContext);
  const role = userInfo?.getUserRole();

  // Initialize state for user data (edit state, password, etc.)
  const [user, setUser] = useState(new UserModel(userInfo));
  const [editMode, setEditMode] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [animate, setAnimate] = useState(false);
  const [loading, setLoading] = useState(false);




const handleSaveChanges = async () => {
    try {
        setLoading(true)
      const data = {
        firstname: user.firstname,
        lastname: user.lastname,
        username: user.username,
        email: user.email,
        mobile: user.mobile,
      };
  
      console.log("Sending data:", data);
  
      const response = await VendorController.updateProfile(data);
  
      setSnackbarMessage(response.data.message);
      setSnackbarSeverity('success');

      setSnackbarOpen(true);
      setEditMode(false);
      setLoading(false)
  
    } catch (error) {
      console.error("Error updating profile:", error);
      
      setSnackbarMessage(error.response?.data?.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      setLoading(false)
    }
  };
  



const handleSavePassword = async () => {
    if (newPassword !== passwordConfirmation) {
        setSnackbarMessage("Passwords do not match.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        return;
      }
  

      if (newPassword.length < 8) {
        setSnackbarMessage("Password must be at least 8 characters long.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        return;
      }

    try {
        setLoading(true)
      const data = {
        current_password: currentPassword,
        password: newPassword,
        password_confirmation: passwordConfirmation
      };
      const response = await VendorController.Updatepassword(data);
      setSnackbarMessage(response.data.message);
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      setPasswordDialogOpen(false);
      setCurrentPassword('');
      setNewPassword('');
      setPasswordConfirmation('');
      setLoading(false)
    } catch (error) {
      setSnackbarMessage(error.response?.data?.message);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      setLoading(false)
      console.error(error);
    }
  };

  // Trigger the animation on component mount
  useEffect(() => {
    setAnimate(true);
  }, []);

  return (
    <Container maxWidth="md" sx={{ marginTop: '100px', display: 'flex', justifyContent: 'center' }}>
      <Paper
        elevation={6}
        sx={{
          padding: '40px',
          width: '100%',
          maxWidth: '700px',
          borderRadius: '15px',
          bgcolor: '#ffffff',
          position: 'relative',
          animation: animate ? `${popAnimation} 0.5s ease-in-out` : 'none',
        }}
      >
        <Badge
          sx={{
            position: 'absolute',
            top: '1px',
            right: '0px',
            padding: '8px 12px',
            borderRadius: '10px',
            color: "#ffffff",
            fontSize: '18px',
            fontWeight: 'bold',
            zIndex: 10,
            backgroundColor: "#133E87",
          }}
        >
          {role}
        </Badge>
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" sx={{ marginBottom: 4 }}>
          <Avatar sx={{ width: 120, height: 120, backgroundColor: teelColor, mb: 2 }} src="">
            <AccountCircleIcon fontSize="large" />
          </Avatar>
          <Typography variant="h4" fontWeight="bold" sx={{ color: '#333' }}>
            {user.username}
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ marginTop: 1 }}>
            {user.email}
          </Typography>
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <CustomTextField
              label="First Name"
              fullWidth
              value={user.firstname}
              onChange={(e) => setUser((prevUser) => ({ ...prevUser, firstname: e.target.value }))}
              disabled={!editMode}
              variant="outlined"
              sx={{ bgcolor: 'white', borderRadius: '8px', boxShadow: 1 }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              label="Last Name"
              fullWidth
              value={user.lastname}
              onChange={(e) => setUser((prevUser) => ({ ...prevUser, lastname: e.target.value }))}
              disabled={!editMode}
              variant="outlined"
              sx={{ bgcolor: 'white', borderRadius: '8px', boxShadow: 1 }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              label="Username"
              fullWidth
              value={user.username}
              onChange={(e) => setUser((prevUser) => ({ ...prevUser, username: e.target.value }))}
              disabled={!editMode}
              variant="outlined"
              sx={{ bgcolor: 'white', borderRadius: '8px', boxShadow: 1 }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              label="Email"
              fullWidth
              value={user.email}
              onChange={(e) => setUser((prevUser) => ({ ...prevUser, email: e.target.value }))}
              disabled={!editMode}
              variant="outlined"
              sx={{ bgcolor: 'white', borderRadius: '8px', boxShadow: 1 }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              label="Phone Number"
              fullWidth
              value={user.mobile}
              onChange={(e) => setUser((prevUser) => ({ ...prevUser, mobile: e.target.value }))}
              disabled={!editMode}
              variant="outlined"
              sx={{ bgcolor: 'white', borderRadius: '8px', boxShadow: 1 }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              label="Address"
              fullWidth
              value={user.address || ''}
              onChange={(e) => setUser((prevUser) => ({ ...prevUser, address: e.target.value }))}
              disabled={!editMode}
              variant="outlined"
              sx={{ bgcolor: 'white', borderRadius: '8px', boxShadow: 1 }}
            />
          </Grid>
        </Grid>

        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ marginTop: 4 }}>
          <Box display="flex" alignItems="center">
            <IconButton color="primary" onClick={() => setPasswordDialogOpen(true)}>
              <LockIcon sx={{ color: teelColor }} /> <Typography sx={{ ml: 1, color: teelColor }}>Change Password</Typography>
            </IconButton>
          </Box>

          <Box display="flex" alignItems="center">
            {editMode ? (
              <>
                <CustomButton onClick={handleSaveChanges} sx={{ marginRight: 2 }}>
                   {loading ? <CircularProgress size={24} /> : 'Save Changes'}
                </CustomButton>
                <CustomCancelButton onClick={() => setEditMode(false)}>
                  Cancel
                </CustomCancelButton>
              </>
            ) : (
              <CustomButton startIcon={<EditIcon />} onClick={() => setEditMode(true)}>
                Edit Profile
              </CustomButton>
            )}
          </Box>
        </Box>

        <Dialog open={passwordDialogOpen} onClose={() => setPasswordDialogOpen(false)}>
          <DialogTitle>Change Password</DialogTitle>
          <DialogContent>
            <CustomTextField
              label="Current Password"
              type="password"
              fullWidth
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              variant="outlined"
              sx={{ mt: 2 }}
            />
            <CustomTextField
              label="New Password"
              type="password"
              fullWidth
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              variant="outlined"
              sx={{ mt: 2 }}
            />
            <CustomTextField
              label="Confirm New Password"
              type="password"
              fullWidth
              value={passwordConfirmation}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
              variant="outlined"
              sx={{ mt: 2 }}
            />
          </DialogContent>
          <DialogActions>
            <CustomCancelButton onClick={() => setPasswordDialogOpen(false)}>
              Cancel 
            </CustomCancelButton>
            <CustomButton onClick={handleSavePassword} sx={{ width: "10%" }}>
                {loading ? <CircularProgress size={24} /> : 'Save'}
            </CustomButton>
          </DialogActions>
        </Dialog>

        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
          <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Paper>
    </Container>
  );
};

export default UserProfile;
