import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Box, Container, Typography, Snackbar, Alert, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import CustomButton from '../../../Components/formComponent/CustomButton';
import CustomTextField from '../../../Components/formComponent/CustomTextField';
import { teelColor, lightOrangeColor, redColor, grayColor, whiteColor } from '../../../utils/config';
import EmployeController from '../../../Controllers/EmployeeController';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import Slide from '@mui/material/Slide';
import CustomCancelButton from '../../../Components/formComponent/CustomCancelButton';
import CustomDeleteButton from '../../../Components/formComponent/CustomDeleteButton';
import Pagination from '@mui/material/Pagination';

const Transition = React.forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />);
const DeleteTransition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const AddAgency = () => {
  const initialFormState = {
    agencyName: '',
    description: '',
    agencyCode: ''
  };

  const [formData, setFormData] = useState(initialFormState);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [openDialog, setOpenDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [agencies, setAgencies] = useState([]);
  const [selectedAgency, setSelectedAgency] = useState(null);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(6);

  const resetForm = () => {
    setFormData(initialFormState);
  };

  const fetchAgencies = async () => {
    try {
      const response = await EmployeController.getagenceinfo();
      setAgencies(response.data.data);
    } catch (error) {
      setSnackbarMessage('An error occurred while fetching agencies');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  useEffect(() => {
    fetchAgencies();
  }, []);

  const filteredAgencies = agencies.filter((agency) =>
    agency.agent_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    agency.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
    agency.agency_code.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    const agencyData = {
      agent_name: formData.agencyName,
      description: formData.description,
      agency_code: formData.agencyCode,
    };

    try {
      setLoading(true);
      await EmployeController.AddAgency(agencyData);
      setSnackbarMessage('Agency added successfully!');
      setSnackbarSeverity('success');
      fetchAgencies();
      resetForm();
      setOpenSnackbar(true);
      setOpenDialog(false);
      setLoading(false);
    } catch (error) {
      setSnackbarMessage('An error occurred');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      setLoading(false);
    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();

    const agencyData = {
      agenceId: selectedAgency.id,
      agent_name: formData.agencyName,
      description: formData.description,
      agency_code: formData.agencyCode,
    };

    try {
      setLoading(true);
      await EmployeController.editAgence(agencyData);
      setSnackbarMessage('Agency updated successfully!');
      setSnackbarSeverity('success');
      fetchAgencies();
      resetForm();
      setOpenSnackbar(true);
      setOpenEditDialog(false);
      setLoading(false);
    } catch (error) {
      setSnackbarMessage('An error occurred');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      const data = {
        agenceId: selectedAgency.id
      };
      setLoading(true);
      await EmployeController.DeleteAgency(data);
      setSnackbarMessage('Agency deleted successfully!');
      setSnackbarSeverity('success');
      fetchAgencies();
      setOpenSnackbar(true);
      setOpenDeleteDialog(false);
      setLoading(false);
    } catch (error) {
      setSnackbarMessage('An error occurred');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      setLoading(false);
    }
  };

  const handleOpenEditDialog = (agency) => {
    setSelectedAgency(agency);
    setFormData({
      agencyName: agency.agent_name,
      description: agency.description,
      agencyCode: agency.agency_code,
    });
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    resetForm();
  };

  const handleCloseAddDialog = () => {
    setOpenDialog(false);
    resetForm();
  };

  const handleInputChange = (field) => (e) => {
    setFormData((prev) => ({
      ...prev,
      [field]: e.target.value
    }));
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const paginatedAgencies = filteredAgencies.slice((page - 1) * rowsPerPage, page * rowsPerPage);

  return (
    <Container maxWidth="ml" sx={{ marginTop: '80px' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ padding: '10px' }}>
        <Grid sx={{ flex: 1, maxWidth: '400px', marginRight: '10px' }}>
          <CustomTextField
            label="Search Agencies"
            variant="outlined"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            fullWidth
            sx={{ height: '40px' }}
          />
        </Grid>
        <CustomButton sx={{ width: "200px" }} variant="contained" color="primary" onClick={() => setOpenDialog(true)}>
          Add Agency
        </CustomButton>
      </Box>

      <Paper sx={{ marginTop: 3, padding: 2, overflow: 'hidden', boxShadow: 'none' }}>
        <TableContainer>
          <Table>
            <TableHead sx={{ background: teelColor }}>
              <TableRow>
                <TableCell sx={{ color: whiteColor }} align="center">Creation Date</TableCell>
                <TableCell sx={{ color: whiteColor }} align="center">Name</TableCell>
                <TableCell sx={{ color: whiteColor }} align="center">Description</TableCell>
                <TableCell sx={{ color: whiteColor }} align="center">Code</TableCell>
                <TableCell sx={{ color: whiteColor }} align="center"><strong>Action</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedAgencies.map((agency) => (
                <TableRow key={agency.id}>
                  <TableCell align="center">{formatDate(agency.created_at)}</TableCell>
                  <TableCell align="center">{agency.agent_name}</TableCell>
                  <TableCell align="center">{agency.description}</TableCell>
                  <TableCell align="center">{agency.agency_code}</TableCell>
                  <TableCell align="center">
                    <IconButton 
                      sx={{ color: lightOrangeColor }} 
                      onClick={() => handleOpenEditDialog(agency)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton 
                      sx={{ color: redColor }} 
                      onClick={() => { setSelectedAgency(agency); setOpenDeleteDialog(true); }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
          <Pagination
            count={Math.ceil(filteredAgencies.length / rowsPerPage)}
            page={page}
            onChange={handleChangePage}
            color="primary"
          />
        </Box>
      </Paper>

      {/* Dialog for adding agency */}
      <Dialog open={openDialog} onClose={handleCloseAddDialog} TransitionComponent={Transition}>
        <DialogTitle>Add New Agency</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ marginTop: "10px" }}>
                <CustomTextField
                  fullWidth
                  label="Agency Name"
                  value={formData.agencyName}
                  onChange={handleInputChange('agencyName')}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  fullWidth
                  label="Description"
                  value={formData.description}
                  onChange={handleInputChange('description')}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  fullWidth
                  label="Agency Code"
                  value={formData.agencyCode}
                  onChange={handleInputChange('agencyCode')}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <CustomButton type="submit" disabled={loading} variant="contained" sx={{ background: teelColor }}>
                  {loading ? <CircularProgress size={24} /> : 'Add Agency'}
                </CustomButton>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <CustomCancelButton onClick={handleCloseAddDialog} color="primary">
            Cancel
          </CustomCancelButton>
        </DialogActions>
      </Dialog>

      {/* Dialog for editing agency */}
      <Dialog open={openEditDialog} onClose={handleCloseEditDialog} TransitionComponent={Transition}>
        <DialogTitle>Edit Agency</DialogTitle>
        <DialogContent>
          <form onSubmit={handleEditSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ marginTop: "10px" }}>
                <CustomTextField
                  fullWidth
                  label="Agency Name"
                  value={formData.agencyName}
                  onChange={handleInputChange('agencyName')}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  fullWidth
                  label="Description"
                  value={formData.description}
                  onChange={handleInputChange('description')}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  fullWidth
                  label="Agency Code"
                  value={formData.agencyCode}
                  onChange={handleInputChange('agencyCode')}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <CustomButton type="submit" disabled={loading} variant="contained" sx={{ background: teelColor }}>
                  {loading ? <CircularProgress size={24} /> : 'Update Agency'}
                </CustomButton>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <CustomCancelButton onClick={handleCloseEditDialog} color="primary">
            Cancel
          </CustomCancelButton>
        </DialogActions>
      </Dialog>

      {/* Dialog for deleting agency */}
      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)} TransitionComponent={DeleteTransition}>
        <DialogTitle>Are you sure you want to delete this agency?</DialogTitle>
        <DialogActions>
          <CustomCancelButton onClick={() => setOpenDeleteDialog(false)} color="primary">
            Cancel
          </CustomCancelButton>
          <CustomDeleteButton onClick={handleDelete} color="secondary">
            {loading ? <CircularProgress size={24} /> : 'Delete'}
          </CustomDeleteButton>
        </DialogActions>
      </Dialog>

      {/* Snackbar for feedback */}
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
        <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AddAgency;





// import React, { useState, useEffect } from 'react';
// import { TextField, Button, Grid, Box, Container, Typography, Snackbar, Alert, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
// import CustomButton from '../../../Components/formComponent/CustomButton';
// import CustomTextField from '../../../Components/formComponent/CustomTextField';
// import { teelColor, lightOrangeColor, redColor, grayColor, whiteColor } from '../../../utils/config';
// import EmployeController from '../../../Controllers/EmployeeController';
// import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';
// import CircularProgress from '@mui/material/CircularProgress';
// import Slide from '@mui/material/Slide';
// import CustomCancelButton from '../../../Components/formComponent/CustomCancelButton';
// import CustomDeleteButton from '../../../Components/formComponent/CustomDeleteButton';
// import Pagination from '@mui/material/Pagination';

// const Transition = React.forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />);
// const DeleteTransition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

// const AddAgency = () => {
//   const [agencyName, setAgencyName] = useState('');
//   const [description, setDescription] = useState('');
//   const [agencyCode, setAgencyCode] = useState('');
//   const [searchQuery, setSearchQuery] = useState('');
//   const [loading, setLoading] = useState(false);
//   const [openSnackbar, setOpenSnackbar] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [snackbarSeverity, setSnackbarSeverity] = useState('success');
//   const [openDialog, setOpenDialog] = useState(false);
//   const [openEditDialog, setOpenEditDialog] = useState(false);
//   const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
//   const [agencies, setAgencies] = useState([]);
//   const [selectedAgency, setSelectedAgency] = useState(null);

//   // Fetch agencies on component mount

//   const [page, setPage] = useState(1); // state for page number
//   const [rowsPerPage, setRowsPerPage] = useState(6); // state for number of rows per page


//   const fetchAgencies = async () => {
//     try {
//       const response = await EmployeController.getagenceinfo();
//       setAgencies(response.data.data);
//     } catch (error) {
//       setSnackbarMessage('An error occurred while fetching agencies');
//       setSnackbarSeverity('error');
//       setOpenSnackbar(true);
//     }
//   };

//   useEffect(() => {
   
//     fetchAgencies();
//   }, []);

//   // Filter agencies based on search query
//   const filteredAgencies = agencies.filter((agency) =>
//     agency.agent_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
//     agency.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
//     agency.agency_code.toLowerCase().includes(searchQuery.toLowerCase())
//   );

//   // Handle form submission for adding new agency
//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const agencyData = {
//       agent_name: agencyName,
//       description,
//       agency_code: agencyCode,
//     };

//     try {
//       setLoading(true);
//       await EmployeController.AddAgency(agencyData);
//       setSnackbarMessage('Agency added successfully!');
//       setSnackbarSeverity('success');
//       fetchAgencies()
//       setAgencyName('');
//       setDescription('');
//       setAgencyCode('');
//       setOpenSnackbar(true);
//       setOpenDialog(false);
//       setLoading(false);
//     } catch (error) {
//       setSnackbarMessage('An error occurred');
//       setSnackbarSeverity('error');
//       setOpenSnackbar(true);
//       setLoading(false);
//     }
//   };

//   // Handle editing an agency
//   const handleEditSubmit = async (e) => {
//     e.preventDefault();

//     const agencyData = {
//         agenceId:selectedAgency.id,
//       agent_name: agencyName,
//       description:description,
//       agency_code: agencyCode,
//     };



//     try {
//       setLoading(true);
//       await EmployeController.editAgence(agencyData);
//       setSnackbarMessage('Agency updated successfully!');
//       setSnackbarSeverity('success');
//       fetchAgencies()
//       setOpenSnackbar(true);
//       setOpenEditDialog(false);
//       setLoading(false);
//     } catch (error) {
//       setSnackbarMessage('An error occurred');
//       setSnackbarSeverity('error');
//       setOpenSnackbar(true);
//       setLoading(false);
//     }
//   };

//   // Handle deleting an agency
//   const handleDelete = async () => {
//     try {
//         const data={
//             agenceId:selectedAgency.id
//         }
//         setLoading(true);
//       await EmployeController.DeleteAgency(data);
//       setSnackbarMessage('Agency deleted successfully!');
//       setSnackbarSeverity('success');
//       fetchAgencies()
//       setOpenSnackbar(true);
//       setOpenDeleteDialog(false);
//       setLoading(false);
//     } catch (error) {
//       setSnackbarMessage('An error occurred');
//       setSnackbarSeverity('error');
//       setOpenSnackbar(true);
//       setLoading(false);
//     }
//   };

//   const formatDate = (dateString) => {
//     const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
//     return new Date(dateString).toLocaleDateString('en-US', options);
//   };

//   const handleChangePage = (event, value) => {
//     setPage(value);
//   };
  

//   // Get paginated agencies
//   const paginatedAgencies = filteredAgencies.slice((page - 1) * rowsPerPage, page * rowsPerPage);


//   return (
//     <Container maxWidth="ml" sx={{ marginTop: '80px' }}>
      

//       <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ padding: '10px' }}>
//         <Grid sx={{ flex: 1, maxWidth: '400px', marginRight: '10px' }}>
//           <CustomTextField
//             label="Search Agencies"
//             variant="outlined"
//             value={searchQuery}
//             onChange={(e) => setSearchQuery(e.target.value)}
//             fullWidth
//             sx={{ height: '40px' }} 
//           />
//         </Grid>
//         <CustomButton sx={{width:"200px"}} variant="contained" color="primary" onClick={() => setOpenDialog(true)}>
//           Add Agency
//         </CustomButton>
//       </Box>

//       <Paper sx={{ marginTop: 3, padding: 2,overflow: 'hidden', boxShadow: 'none' }}>
//         <TableContainer>
//           <Table>
//           <TableHead sx={{background:teelColor}}>
//               <TableRow>
//                 <TableCell sx={{color:whiteColor,}} align="center">Creation Date</TableCell>
//                 <TableCell sx={{color:whiteColor}} align="center">Name</TableCell>
//                 <TableCell sx={{color:whiteColor}} align="center">Description</TableCell>
//                 <TableCell sx={{color:whiteColor}} align="center">Code</TableCell>
//                 <TableCell sx={{color:whiteColor}} align="center"><strong>Action</strong></TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {paginatedAgencies.map((agency) => (
//                 <TableRow key={agency.id}>
//                   <TableCell align="center">{formatDate(agency.created_at)}</TableCell>
//                   <TableCell align="center">{agency.agent_name}</TableCell>
//                   <TableCell align="center">{agency.description}</TableCell>
//                   <TableCell align="center">{agency.agency_code}</TableCell>
//                   <TableCell align="center">
//                     <IconButton sx={{ color: lightOrangeColor }} onClick={() => { setSelectedAgency(agency); setAgencyName(agency.agent_name); setDescription(agency.description); setAgencyCode(agency.agency_code); setOpenEditDialog(true); }}>
//                       <EditIcon />
//                     </IconButton>
//                     <IconButton sx={{ color: redColor }} onClick={() => { setSelectedAgency(agency); setOpenDeleteDialog(true); }}>
//                       <DeleteIcon />
//                     </IconButton>
//                   </TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//         <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
//   <Pagination
//     count={Math.ceil(filteredAgencies.length / rowsPerPage)}
//     page={page}
//     onChange={handleChangePage}
//     color="primary"
//   />
// </Box>

//       </Paper>

//       {/* Dialog for adding agency */}
//       <Dialog open={openDialog} onClose={() => setOpenDialog(false)} TransitionComponent={Transition}>
//         <DialogTitle>Add New Agency</DialogTitle>
//         <DialogContent>
//           <form onSubmit={handleSubmit}>
//             <Grid container spacing={2}>
//               <Grid item xs={12} sx={{marginTop:"10px"}}>
//                 <CustomTextField
//                   fullWidth
//                   label="Agency Name"
//                   value={agencyName}
//                   onChange={(e) => setAgencyName(e.target.value)}
//                   required
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <CustomTextField
//                   fullWidth
//                   label="Description"
//                   value={description}
//                   onChange={(e) => setDescription(e.target.value)}
//                   required
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <CustomTextField
//                   fullWidth
//                   label="Agency Code"
//                   value={agencyCode}
//                   onChange={(e) => setAgencyCode(e.target.value)}
//                   required
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <CustomButton type="submit" disabled={loading} variant="contained" sx={{ background: teelColor }}>
//                   {loading ? <CircularProgress size={24} /> : 'Add Agency'}
//                 </CustomButton>
//               </Grid>
//             </Grid>
//           </form>
//         </DialogContent>
//         <DialogActions>
//           <CustomCancelButton onClick={() => setOpenDialog(false)} color="primary">
//             Cancel
//           </CustomCancelButton>
//         </DialogActions>
//       </Dialog>

//       {/* Dialog for editing agency */}
//       <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)} TransitionComponent={Transition}>
//         <DialogTitle>Edit Agency</DialogTitle>
//         <DialogContent>
//           <form onSubmit={handleEditSubmit}>
//             <Grid container spacing={2}>
//               <Grid item xs={12} sx={{marginTop:"10px"}}>
//                 <CustomTextField
//                   fullWidth
//                   label="Agency Name"
//                   value={agencyName}
//                   onChange={(e) => setAgencyName(e.target.value)}
//                   required
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <CustomTextField
//                   fullWidth
//                   label="Description"
//                   value={description}
//                   onChange={(e) => setDescription(e.target.value)}
//                   required
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <CustomTextField
//                   fullWidth
//                   label="Agency Code"
//                   value={agencyCode}
//                   onChange={(e) => setAgencyCode(e.target.value)}
//                   required
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <CustomButton type="submit" disabled={loading} variant="contained" sx={{ background: teelColor }}>
//                   {loading ? <CircularProgress size={24} /> : 'Update Agency'}
//                 </CustomButton>
//               </Grid>
//             </Grid>
//           </form>
//         </DialogContent>
//         <DialogActions>
//           <CustomCancelButton onClick={() => setOpenEditDialog(false)} color="primary">
//             Cancel
//           </CustomCancelButton>
//         </DialogActions>
//       </Dialog>

//       {/* Dialog for deleting agency */}
//       <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)} TransitionComponent={DeleteTransition}>
//         <DialogTitle>Are you sure you want to delete this agency?</DialogTitle>
//         <DialogActions>
//           <CustomCancelButton onClick={() => setOpenDeleteDialog(false)} color="primary">
//             Cancel
//           </CustomCancelButton>
//           <CustomDeleteButton onClick={handleDelete} color="secondary">
//              {loading ? <CircularProgress size={24} /> : 'Delete'}
//           </CustomDeleteButton>
//         </DialogActions>
//       </Dialog>

//       {/* Snackbar for feedback */}
//       <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
//         <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
//           {snackbarMessage}
//         </Alert>
//       </Snackbar>
//     </Container>
//   );
// };

// export default AddAgency;
