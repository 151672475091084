// src/components/CustomTextField.js
import React from 'react';
import { TextField } from '@mui/material';
import { teelColor } from '../../utils/config';

function CustomTextField({ label, variant = "outlined", fullWidth = true, sx = {}, ...props }) {
  return (
    <TextField
      label={label}
      variant={variant}
      fullWidth={fullWidth}
      {...props}
      sx={{
        ...sx,
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused fieldset': {
            borderColor: teelColor,
          },
        },
        '& label.Mui-focused': {
          color: teelColor,
        },
        '& .MuiInputBase-input': {
          color: teelColor,
        },
      }}
    />
  );
}

export default CustomTextField;
