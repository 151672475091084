import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { Grid } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import CustomTextField from '../../../Components/formComponent/CustomTextField';
import CustomSelect from '../../../Components/formComponent/CustomSelect';
import CustomButton from '../../../Components/formComponent/CustomButton';
import { teelColor, redColor, lightOrangeColor, grayColor, whiteColor, urlImage } from '../../../utils/config';
import VendorController from '../../../Controllers/VendorControllers';
import CustomCancelButton from '../../../Components/formComponent/CustomCancelButton';
import CustomDeleteButton from '../../../Components/formComponent/CustomDeleteButton';
import EmployeController from '../../../Controllers/EmployeeController';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const DeletTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const VendorendorProductdata = () => {
  const [page, setPage] = useState(0);

  const [productName, setProductName] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [stock, setStock] = useState('');
  const [price, setPrice] = useState('');
  const [categorieId, setCategorieId] = useState('');
  const [productImages, setProductImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDetailDialog, setOpenDetailDialog] = useState(false); // État pour le modal des détails
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [imageFiles, setImageFiles] = useState([]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [categorieData, setCategorieData] = useState([])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const data = await EmployeController.fetchvendorproduct()
        console.log('dataprodu', data.data.products);
        setRows(data.data.products);
      } catch (error) {
        console.error('Failed to fetch products:', error);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    if (selectedProduct) {
      const images = [selectedProduct.product_images1, selectedProduct.product_images2, selectedProduct.product_images3];
      setImagePreviews(images.filter(img => img).map(img => `${urlImage}/${img}`));
    }
  }, [selectedProduct]);

  const formatDate = (dateString) => {
    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',

    };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const handleOpenDeleteDialog = (product) => {
    setSelectedProduct(product);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedProduct(null);
  };
  

  



  const handleOpenDetailDialog = (product) => {
    setSelectedProduct(product);
    setOpenDetailDialog(true);
  };

  const handleCloseDetailDialog = () => {
    setOpenDetailDialog(false);
    setSelectedProduct(null);
  };

  const handleDelete = async () => {
    try {
      setLoading(true)
      const response = await VendorController.deleteProduct(selectedProduct.id);
      if (response.status === 200) {
        setRows(rows.filter((row) => row.id !== selectedProduct.id));
        handleCloseDeleteDialog();
        toast.success('Product Delete successfully!');
        setLoading(false)
      }

    } catch (error) {
      console.error('Failed to delete productssss:', error);
      setLoading(false)
    }
  };




  









  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 500 }}>
        <Table sx={{ minWidth: 550 }}>
          <TableHead sx={{ background: teelColor, color: whiteColor }}>
            <TableRow>
              <TableCell sx={{ color: whiteColor, width: 200 }} align='center'><strong>Created At</strong></TableCell>
              <TableCell sx={{ color: whiteColor }} align="center"><strong>Vendor Name</strong></TableCell>
              <TableCell sx={{ color: whiteColor }} align="center"><strong>Vendor Email</strong></TableCell>
              <TableCell sx={{ color: whiteColor }} align="center"><strong>Vendor Mobile</strong></TableCell>
              <TableCell sx={{ color: whiteColor }} align="center"><strong>Product Name</strong></TableCell>
              <TableCell sx={{ color: whiteColor }} align="center"><strong>Category</strong></TableCell>
              <TableCell sx={{ color: whiteColor }} align="center"><strong>Price</strong></TableCell>
              <TableCell sx={{ color: whiteColor }} align="center"><strong>Stock</strong></TableCell>
              <TableCell sx={{ color: whiteColor }} align="center"><strong>Image</strong></TableCell>
              <TableCell sx={{ color: whiteColor }} align="center"><strong>Action</strong></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                <TableCell align='center'>{formatDate(row.created_at)}</TableCell>
                <TableCell align="center">{row.vendor_name}</TableCell>
                <TableCell align="center">{row.vendor_mobile}</TableCell>
                <TableCell align="center">{row.vendor_email}</TableCell>



                <TableCell align="center">{row.product_name}</TableCell>
                <TableCell align="center">{row.categories_name}</TableCell>
                <TableCell align="center">{row.price}</TableCell>
                <TableCell align="center">{row.stock}</TableCell>
                <TableCell align="center">
                  <img
                    src={`${urlImage}${row.product_images1}`}
                    alt="Product"
                    style={{
                      width: '50px',
                      height: '50px',
                      borderRadius: '20%',
                      objectFit: 'cover',
                      cursor: 'pointer'
                    }}
                    onClick={() => handleOpenDetailDialog(row)}
                  />
                </TableCell>
                <TableCell align="center">
                  <IconButton sx={{ color: teelColor }} aria-label="info" color="primary" onClick={() => handleOpenDetailDialog(row)}>
                    <InfoIcon />
                  </IconButton>
                  <IconButton sx={{ color: redColor }} aria-label="delete" onClick={() => ''}>
                    <DeleteIcon />
                  </IconButton>

                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />




      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Dialog open={openDetailDialog} onClose={handleCloseDetailDialog} TransitionComponent={Transition} fullWidth maxWidth="md">
        <DialogTitle>Product Details</DialogTitle>
        <DialogContent>
          {selectedProduct && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                {/* Image principale */}
                <Grid>
                  <img
                    src={imagePreviews[0]}
                    alt="Product Image"
                    style={{ width: '100%', height: '300px', objectFit: 'cover', marginBottom: '10px' }}
                  />
                </Grid>

                <Grid item xs={12}>
                  {/* Miniatures */}
                  <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    {imagePreviews.map((preview, index) => (
                      <img
                        key={index}
                        src={preview}
                        alt={`Thumbnail ${index + 1}`}
                        style={{
                          width: '50px',
                          height: '60px',
                          objectFit: 'cover',
                          marginRight: '10px',
                          border: index === 0 ? '2px solid #000' : '1px solid #ccc',
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          // Logique pour changer l'image principale
                          const newPreviews = [...imagePreviews];
                          [newPreviews[0], newPreviews[index]] = [newPreviews[index], newPreviews[0]];
                          setImagePreviews(newPreviews);
                        }}
                      />
                    ))}
                  </div>
                </Grid>
              </Grid>

              <Grid item xs={12} md={6}>
                <Grid sx={{ marginBottom: 1 }}>
                  <Typography variant="body2" color="textSecondary">
                    {new Date(selectedProduct.created_at).toLocaleDateString()} at {new Date(selectedProduct.created_at).toLocaleTimeString()}
                  </Typography>
                </Grid>
                <Typography variant="h6">{selectedProduct.product_name}</Typography>
                <Typography variant="body1">Price: {selectedProduct.price}</Typography>
                <Typography variant="body1">Stock: {selectedProduct.stock}</Typography>
                <Typography variant="body1" paragraph>
                  {/* {selectedProduct.product_description} */}
                  {selectedProduct.product_description.length > 400
                    ? `${selectedProduct.product_description.substring(0, 400)}...`
                    : selectedProduct.product_description}
                </Typography>
                {/* <Typography variant="body1">Description: {selectedProduct.product_description}</Typography> */}
              </Grid>

            </Grid>
          )}
          <Grid>
            <DialogActions>
              <Button sx={{ color: teelColor }} onClick={handleCloseDetailDialog} color="primary">Cancel</Button>
            </DialogActions>
          </Grid>

        </DialogContent>
      </Dialog>

      
    </Paper>
  );
};

export default VendorendorProductdata;




