// ** MUI Imports
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// ** Demo Components Imports
import { teelColor } from '../../../utils/config';
import VendorendorProductdata from '../../tables/VendorListeTable/VendorproductData';


const VendorproductListe = () => {
  

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Grid container alignItems="center" spacing={10}>
          <Grid item>
            <Typography variant="h5" sx={{ color: teelColor }}>
              Vendor Liste
            </Typography>
          </Grid>
          
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <VendorendorProductdata />
      </Grid>
    </Grid>
  );
};

export default VendorproductListe;
