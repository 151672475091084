// ** MUI Imports
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// ** Demo Components Imports
import VendorsListData from '../../tables/VendorListeTable/VendorListe';
import { teelColor } from '../../../utils/config';


const UserVendor = () => {
 

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
       
      </Grid>
      <Grid item xs={12}>
        <VendorsListData />
      </Grid>
    </Grid>
  );
};

export default UserVendor;
