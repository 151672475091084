import React, { useState, useEffect } from 'react';
import {
  Paper,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Alert,
  CircularProgress,
  Card,
  CardMedia,
  CardContent,
  Typography,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import CustomButton from '../../Components/formComponent/CustomButton'; 
import CustomCancelButton from '../../Components/formComponent/CustomCancelButton';
import { teelColor, whiteColor, urlImage } from '../../utils/config';
import VendorController from '../../Controllers/VendorControllers';

const ViewOrders = () => {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [productPage, setProductPage] = useState(0);
  const productsPerPage = 3;
  const [open, setOpen] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  // Function to open the dialog with the selected order
  const handleOpen = (order) => {
    setSelectedOrder(order);
    setProductPage(0);
    setOpen(true);
  };

  // Function to close the dialog
  const handleClose = () => {
    setOpen(false);
    setSelectedOrder(null);
  };

  // Functions for pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleProductNextPage = () => {
    setProductPage((prevPage) => prevPage + 1);
  };

  const handleProductPreviousPage = () => {
    setProductPage((prevPage) => prevPage - 1);
  };

  // Fetch orders when component mounts
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const orderResponse = await VendorController.getTheVendororders();
        setOrders(orderResponse.data.orders);
        console.log(orderResponse.data.orders)
      } catch (error) {
        console.error('Erreur lors du chargement des commandes :', error);
      }
    };
    fetchOrders();
  }, []);

  // Function to validate order
  const validatedorder = async (orderId) => {
    try {
      setLoading(true);
      const response = await VendorController.validetedorder({ orderId });
      setSuccess(response.data.message || 'Validation effectuée');
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 3000);
      setLoading(false);
      handleClose();
    } catch (error) {
      setLoading(false);
      handleClose();
      setError(error.response?.data?.message || "Impossible de récupérer les rôles");
      setShowError(true);
      setTimeout(() => setShowError(false), 3000);
    }
  };

  // Paginate orders for display
  const paginatedRows = orders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  // Get order status based on status code
  const getStatusOrders = (status) => {
    switch (status) {
      case 1: return 'Pending';
      case 2: return 'Processing';
      case 3: return 'Validated';
      case 4: return 'Delivered'; 
      case 5: return 'Cancelled';
      default: return 'Unknown status';
    }
  };

  return (
    <>
      <Paper sx={{ marginTop: 10, width: '100%', overflow: 'hidden' }}>
        {showError && <Alert severity="error">{error}</Alert>}
        {showSuccess && <Alert severity="success">{success}</Alert>}

        <TableContainer sx={{ maxHeight: 500 }}>
          <Table sx={{ minWidth: 550 }} aria-label="orders data table">
            <TableHead sx={{ background: teelColor, color: whiteColor }}>
              <TableRow>
                <TableCell sx={{ color: whiteColor }} align="center">Creation Date</TableCell>
                <TableCell sx={{ color: whiteColor }} align="center">Username</TableCell>
                <TableCell sx={{ color: whiteColor }} align="center">Email</TableCell>
                <TableCell sx={{ color: whiteColor }} align="center">Total</TableCell>
                <TableCell sx={{ color: whiteColor }} align="center">Status</TableCell>
                <TableCell sx={{ color: whiteColor }} align="center"><strong>Action</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">No orders found.</TableCell>
                </TableRow>
              ) : (
                paginatedRows.map((order) => (
                  <TableRow key={order.order_id}>
                    <TableCell align="center">
                      {new Date(order.created_at).toLocaleDateString()} {new Date(order.created_at).toLocaleTimeString()}
                    </TableCell>
                    <TableCell align="center">{order.username}</TableCell>
                    <TableCell align="center">{order.useremail}</TableCell>
                    <TableCell align="center">{order.total_price} $</TableCell>
                    <TableCell align="center">
                      {order.products && order.products.length > 0
                        ? getStatusOrders(order.products[0].status)
                        : 'Statut not found'}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton sx={{ color: teelColor }} aria-label="info" onClick={() => handleOpen(order)}>
                        <InfoIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={orders.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        aria-labelledby="order-details-dialog"
      >
        <DialogTitle id="order-details-dialog" sx={{ textAlign: 'center' }}>
        Order Details
        </DialogTitle>
        <DialogContent dividers>
          {selectedOrder && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <strong>Creation Date : </strong>{' '}
                    {new Date(selectedOrder.created_at).toLocaleDateString()}
                  </Grid>
                  <Grid item xs={12}>
                    <strong>Username : </strong> {selectedOrder.username}
                  </Grid>
                  <Grid item xs={12}>
                    <strong>Email : </strong> {selectedOrder.useremail}
                  </Grid>
                  <Grid item xs={12}>
                    <strong>Total Amount: </strong> {selectedOrder.total_price} FCFA
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={6}>
                <Grid container spacing={2} alignItems="stretch">
                  {selectedOrder.products && selectedOrder.products.length > 0 ? (
                    <>
                      {selectedOrder.products
                        .slice(productPage * productsPerPage, productPage * productsPerPage + productsPerPage)
                        .map((product, index) => (
                          <Grid item xs={12} sm={6} md={4} key={index}>
                            <Card
                              sx={{
                                height: '210px',
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                marginBottom: 2,
                              }}
                            >
                              <CardMedia
                                component="img"
                                height="100"
                                image={product.product_images1 ? `${urlImage}${product.product_images1}` : '/images/default-product.png'}
                                alt={product.product_name}
                              />
                              <CardContent>
                                <Typography variant="h7" sx={{ fontSize: '12px', fontWeight: 'bold' }}>
                                  {product.product_name}
                                </Typography>
                                <Typography variant="body2">
                                  {product.quantity} units - {product.price} $
                                </Typography>
                              </CardContent>
                            </Card>
                          </Grid>
                        ))}
                      {selectedOrder.products.length > productsPerPage && (
                        <Grid item xs={12}>
                          <Button
                            variant="outlined"
                            onClick={handleProductPreviousPage}
                            disabled={productPage === 0}
                          >
                            Previous
                          </Button>
                          <Button
                            variant="outlined"
                            onClick={handleProductNextPage}
                            disabled={(productPage + 1) * productsPerPage >= selectedOrder.products.length}
                          >
                            Next
                          </Button>
                        </Grid>
                      )}
                    </>
                  ) : (
                    <Grid item xs={12}>
                      <Typography>No products found in this order.</Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <CustomButton sx={{ color: whiteColor, width: 250 }} onClick={() => validatedorder(selectedOrder.order_id)} >
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Validate the order'}
          </CustomButton>

          <CustomCancelButton onClick={handleClose} >
            Cancel
          </CustomCancelButton>
        </DialogActions>
        {/* <DialogActions>
          <CustomCancelButton onClick={handleClose} />
          <CustomButton
            onClick={() => validatedorder(selectedOrder.order_id)}
            loading={loading}
            disabled={selectedOrder && selectedOrder.products.length === 0}
          >
            Valider
          </CustomButton>
        </DialogActions> */}
      </Dialog>
    </>
  );
};

export default ViewOrders;






