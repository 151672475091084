import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { Grid } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import CustomTextField from '../../Components/formComponent/CustomTextField';
import CustomSelect from '../../Components/formComponent/CustomSelect';
import CustomButton from '../../Components/formComponent/CustomButton';

import { teelColor, redColor, lightOrangeColor, grayColor, whiteColor, urlImage } from '../../utils/config';
import VendorController from '../../Controllers/VendorControllers';
import CustomCancelButton from '../../Components/formComponent/CustomCancelButton';
import CustomDeleteButton from '../../Components/formComponent/CustomDeleteButton';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const DeletTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ViewProducts = () => {
  const [page, setPage] = useState(0);

  const [productName, setProductName] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [stock, setStock] = useState('');
  const [price, setPrice] = useState('');
  const [categorieId, setCategorieId] = useState('');
  const [productImages, setProductImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDetailDialog, setOpenDetailDialog] = useState(false); // État pour le modal des détails
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [imageFiles, setImageFiles] = useState([]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [categorieData,setCategorieData]=useState([])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const data = await VendorController.getProduct();
        console.log(data.data.products);
        setRows(data.data.products);
      } catch (error) {
        console.error('Failed to fetch products:', error);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    if (selectedProduct) {
      const images = [selectedProduct.product_images1, selectedProduct.product_images2, selectedProduct.product_images3];
      setImagePreviews(images.filter(img => img).map(img => `${urlImage}/${img}`));
    }
  }, [selectedProduct]);

  const formatDate = (dateString) => {
    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',

    };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const handleOpenDeleteDialog = (product) => {
    setSelectedProduct(product);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedProduct(null);
  };
  const handleOpenEditDialog = (product) => {
    setSelectedProduct(product);
    setProductName(product.product_name);
    setProductDescription(product.product_description);
    setStock(product.stock);
    setPrice(product.price);
    setCategorieId(product.categorie_id);
    setProductImages([product.product_images1, product.product_images2, product.product_images3]);
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setSelectedProduct(null);
  };



  const handleOpenDetailDialog = (product) => {
    setSelectedProduct(product);
    setOpenDetailDialog(true);
  };

  const handleCloseDetailDialog = () => {
    setOpenDetailDialog(false);
    setSelectedProduct(null);
  };

  const handleDelete = async () => {
    try {
      setLoading(true)
     const response= await VendorController.deleteProduct(selectedProduct.id);
     if(response.status===200){
      setRows(rows.filter((row) => row.id !== selectedProduct.id));
      handleCloseDeleteDialog();
      toast.success('Product Delete successfully!');
      setLoading(false)
     }

    } catch (error) {
      console.error('Failed to delete productssss:', error);
      setLoading(false)
    }
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();

    // Création d'un objet contenant les données du produit
    const productData = {
      product_name: productName,
      product_description: productDescription,
      stock,
      price,
      categorie_id: categorieId,
    };
    console.log('voila les data recuperer: ',productData)
    try {
      setLoading(true)
      console.log('ID du produit:', selectedProduct.id);

      if (!selectedProduct.id) {
        throw new Error('Product ID is not defined');
      }

      // Appel à la méthode pour mettre à jour le produit
      const response = await VendorController.updateProduct(selectedProduct.id, productData);
      const dataResponse = response.data;
      console.log(response.data.product);

      // Mise à jour de l'état du tableau des produits après modification
      const updatedRows = rows.map((row) =>
        row.id === selectedProduct.id
          ? { ...row, product_name: productName, product_description: productDescription, stock, price, categorie_id: categorieId }
          : row
      );

      if (response.status === 200) {
        setRows(updatedRows);
        handleCloseEditDialog();
        toast.success('Product updated successfully!');
      }
      if(response.status===422){
        const error=response.data.error
        toast.error(error)
      }

      
      setLoading(false)
    } catch (error) {
      console.error('Failed to update product:', error);
      toast.error('An error occurred. Please try again.');
    }
};



  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    setImageFiles(files);
    setImagePreviews(files.map(file => URL.createObjectURL(file)));
  };



  

  useEffect(() => {
    const fetchcategoriesdatas = async () => {
      try {
        const response = await VendorController.getproductcategory();
        console.log('Rôles récupérés:', response.data.categories);
        setCategorieData(response.data.categories);
      } catch (error) {
        console.error(error);
        setError("Impossible de récupérer les rôles");
        setShowError(true);
        toast.error("Impossible de récupérer les rôles");
        setTimeout(() => setShowError(false), 3000);
      }
    };
    fetchcategoriesdatas();
  }, []);

  const CategoriesData = categorieData.map((category) => ({
    value: category.id,
    label: category.name,
  }));


  return (
    <Paper sx={{marginTop:10, width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 500 }}>
        <Table>
          <TableHead sx={{ background: teelColor, color: whiteColor }}>
            <TableRow>

              <TableCell sx={{ color: whiteColor }} align="center"><strong>Product Image</strong></TableCell>
              <TableCell sx={{ color: whiteColor }} align="center"><strong>Product Name</strong></TableCell>
               <TableCell sx={{ color: whiteColor }} align="center"><strong>Category</strong></TableCell>
              <TableCell sx={{ color: whiteColor }} align="center"><strong>Price</strong></TableCell>
              <TableCell sx={{ color: whiteColor }} align="center"><strong>Stock</strong></TableCell>
              <TableCell sx={{ color: whiteColor, width: 200 }} align='center'><strong>Created At</strong></TableCell>
              <TableCell sx={{ color: whiteColor }} align="center"><strong>Action</strong></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>

                <TableCell align="center">
                  <img
                    src={`${urlImage}${row.product_images1}`}
                    alt="Product"
                    style={{
                      width: '50px',
                      height: '50px',
                      borderRadius: '20%',
                      objectFit: 'cover',
                      cursor: 'pointer'
                    }}
                    onClick={() => handleOpenDetailDialog(row)}
                  />
                </TableCell>
                <TableCell align="center">{row.product_name}</TableCell>
                <TableCell align="center">{row.categories_name}</TableCell>
                <TableCell align="center">{row.price}</TableCell>
                <TableCell align="center">{row.stock}</TableCell>
                <TableCell align='center'>{formatDate(row.created_at)}</TableCell>
                <TableCell align="center">
                  <IconButton sx={{ color: teelColor }} aria-label="info" color="primary" onClick={() => handleOpenDetailDialog(row)}>
                    <InfoIcon />
                  </IconButton>
                  <IconButton sx={{ color: lightOrangeColor }} aria-label="edit" onClick={() => handleOpenEditDialog(row)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton sx={{ color: redColor }} aria-label="delete" color="error" onClick={() => handleOpenDeleteDialog(row)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog} TransitionComponent={DeletTransition}>
        <DialogTitle>Confirmation of Deletion</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this item?
        </DialogContent>
        <DialogActions>
          <CustomCancelButton sx={{ color: whiteColor,width:150 }} onClick={handleCloseDeleteDialog}>Cancel</CustomCancelButton>
          <CustomDeleteButton sx={{ color: whiteColor,width:150 }} onClick={handleDelete} color="error">{loading ? <CircularProgress size={24} color="inherit" /> : 'Delete'}</CustomDeleteButton>
        </DialogActions>
      </Dialog>

      <Dialog open={openEditDialog} onClose={handleCloseEditDialog} TransitionComponent={Transition}>
        <DialogTitle>Edit Product</DialogTitle>
        <DialogContent>
          {selectedProduct && (
            <form onSubmit={handleEditSubmit}>
              <Grid container spacing={2}>
                {/* Champs du formulaire en grille */}
                <Grid item xs={12} md={6}>
                  <CustomTextField
                    margin="dense"
                    name="product_name"
                    value={productName}
                    onChange={(e) => setProductName(e.target.value)}
                    label="Product Name"
                    fullWidth
                    defaultValue={selectedProduct.product_name}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomTextField
                    margin="dense"
                    name="price"
                    label="Price"
                    type="number"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    fullWidth
                    defaultValue={selectedProduct.price}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomTextField
                    margin="dense"
                    name="stock"
                    label="Stock"
                    type="number"
                    value={stock}
                    onChange={(e) => setStock(e.target.value)}
                    fullWidth
                    defaultValue={selectedProduct.stock}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomSelect
                    value={categorieId}
                    onChange={(e) => setCategorieId(e.target.value)}
                    label="Category"
                    options={CategoriesData}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomTextField
                    margin="dense"
                    fullWidth
                    label="Product Description"
                    multiline
                    rows={4}
                    value={productDescription}
                    onChange={(e) => setProductDescription(e.target.value)}
                    defaultValue={selectedProduct.product_description}
                  />
                </Grid>

                
                <Grid item xs={12}>
                  {/* Affichage des images téléchargées */}
                  <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px' }}>
                    {imagePreviews.map((preview, index) => (
                      <div key={index} style={{ marginRight: '10px' }}>
                        <img
                          src={preview}
                          alt={`Product Preview ${index + 1}`}
                          style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                        />
                      </div>
                    ))}
                  </div>
                </Grid>
              </Grid>
              <DialogActions>
                <Button sx={{ color: grayColor }} onClick={handleCloseEditDialog} color="primary">Cancel</Button>
                <CustomButton sx={{ color: whiteColor,width:150 }} disabled={loading} type="submit">{loading ? <CircularProgress size={24} color="inherit" /> : 'Update'}</CustomButton>
              </DialogActions>
            </form>
          )}
        </DialogContent>
      </Dialog>
      <ToastContainer
              position="top-center"
              autoClose={2000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />

      <Dialog open={openDetailDialog} onClose={handleCloseDetailDialog} TransitionComponent={Transition} fullWidth maxWidth="md">
        <DialogTitle>Product Details</DialogTitle>
        <DialogContent>
          {selectedProduct && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                {/* Image principale */}
                <Grid>
                  <img
                    src={imagePreviews[0]}
                    alt="Product Image"
                    style={{ width: '100%', height: '300px', objectFit: 'cover', marginBottom: '10px' }}
                  />
                </Grid>

                <Grid item xs={12}>
                  {/* Miniatures */}
                  <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    {imagePreviews.map((preview, index) => (
                      <img
                        key={index}
                        src={preview}
                        alt={`Thumbnail ${index + 1}`}
                        style={{
                          width: '50px',
                          height: '60px',
                          objectFit: 'cover',
                          marginRight: '10px',
                          border: index === 0 ? '2px solid #000' : '1px solid #ccc',
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          // Logique pour changer l'image principale
                          const newPreviews = [...imagePreviews];
                          [newPreviews[0], newPreviews[index]] = [newPreviews[index], newPreviews[0]];
                          setImagePreviews(newPreviews);
                        }}
                      />
                    ))}
                  </div>
                </Grid>
              </Grid>

              <Grid item xs={12} md={6}>
                <Grid sx={{ marginBottom: 1 }}>
                  <Typography variant="body2" color="textSecondary">
                    {new Date(selectedProduct.created_at).toLocaleDateString()} at {new Date(selectedProduct.created_at).toLocaleTimeString()}
                  </Typography>
                </Grid>
                <Typography variant="h6">{selectedProduct.product_name}</Typography>
                <Typography variant="body1">Price: {selectedProduct.price}</Typography>
                <Typography variant="body1">Stock: {selectedProduct.stock}</Typography>
                <Typography variant="body1" paragraph>
                  {/* {selectedProduct.product_description} */}
                  {selectedProduct.product_description.length > 400
                    ? `${selectedProduct.product_description.substring(0, 400)}...`
                    : selectedProduct.product_description}
                </Typography>
                {/* <Typography variant="body1">Description: {selectedProduct.product_description}</Typography> */}
              </Grid>

            </Grid>
          )}
          <Grid>
            <DialogActions>
              <Button sx={{ color: teelColor }} onClick={handleCloseDetailDialog} color="primary">Cancel</Button>
            </DialogActions>
          </Grid>

        </DialogContent>
      </Dialog>

      


    </Paper>
  );
};

export default ViewProducts;








// import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';
// import InfoIcon from '@mui/icons-material/Info';
// import { Avatar,
//    Button, 
//    CircularProgress,
//     Dialog,
//      DialogActions, 
//      DialogContent,
//      Container,
//       DialogTitle,
//        Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
// import React, { useEffect, useState } from 'react';
// import { teelColor } from '../../utils/config';

// function ViewProducts() {
//   const [products, setProducts] = useState([]);
//   const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
//   const [openEditDialog, setOpenEditDialog] = useState(false);
//   const [openDetailDialog, setOpenDetailDialog] = useState(false);
//   const [selectedProduct, setSelectedProduct] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [imagePreviews, setImagePreviews] = useState([]);
//   const [productName, setProductName] = useState('');
//   const [price, setPrice] = useState('');
//   const [stock, setStock] = useState('');

//   const handleCloseDetailDialog = () => {
//     setOpenDetailDialog(false);
//     setSelectedProduct(null);
//     setImagePreviews([]);
//   };

//   const handleCloseEditDialog = () => {
//     setOpenEditDialog(false);
//     setSelectedProduct(null);
//   };

//   const handleCloseDeleteDialog = () => {
//     setOpenDeleteDialog(false);
//     setSelectedProduct(null);
//   };

//   const handleOpenDetailDialog = (product) => {
//     setSelectedProduct({
//       ...product,
//       otherImages: Array.isArray(product.otherImages) ? product.otherImages : [] // Vérification que otherImages est un tableau
//     });
//     setImagePreviews(product.otherImages || []);
//     setOpenDetailDialog(true);
//   };

//   const handleOpenEditDialog = (product) => {
//     setSelectedProduct(product);
//     setProductName(product.product_name || '');
//     setPrice(product.price || '');
//     setStock(product.stock || '');
//     setOpenEditDialog(true);
//   };

//   const handleDelete = () => {
//     setLoading(true);
//     setTimeout(() => {
//       setLoading(false);
//       setOpenDeleteDialog(false);
//     }, 2000);
//   };

//   const handleEditSubmit = (e) => {
//     e.preventDefault();
//     setLoading(true);
//     // Logique pour mettre à jour les données du produit
//     setTimeout(() => {
//       setLoading(false);
//       handleCloseEditDialog();
//     }, 2000);
//   };

//   useEffect(() => {
//     const fetchedProducts = [
//       { id: 1, image: 'image-url-1', product_name: 'didier', category: 'Home & Garden', price: 345, stock: 12345, created_at: '2024-10-24T22:18:06', otherImages: ['image-url-1', 'image-url-2'] },
//       { id: 2, image: 'image-url-2', product_name: 'robe', category: 'Women', price: 432, stock: 120, created_at: '2024-10-24T22:07:08', otherImages: ['image-url-3'] },
//     ];
//     setProducts(fetchedProducts);
//   }, []);

//   return (
//     <Container maxWidth="ml">
//     <Paper sx={{marginTop:10, width: '100%', overflow: 'hidden' }}>
//       <Typography>My orders Listes</Typography>
//     <TableContainer>
//       <Table>
//         <TableHead>
//           <TableRow style={{ backgroundColor: teelColor, color: 'white' }}>
//             <TableCell style={{ color: 'white' }}>Product Image</TableCell>
//             <TableCell style={{ color: 'white' }}>Product Name</TableCell>
//             <TableCell style={{ color: 'white' }}>Category</TableCell>
//             <TableCell style={{ color: 'white' }}>Price</TableCell>
//             <TableCell style={{ color: 'white' }}>Stock</TableCell>
//             <TableCell style={{ color: 'white' }}>Created At</TableCell>
//             <TableCell style={{ color: 'white' }}>Action</TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {products.map((product) => (
//             <TableRow key={product.id}>
//               <TableCell><Avatar src={product.image} alt={product.product_name} /></TableCell>
//               <TableCell>{product.product_name}</TableCell>
//               <TableCell>{product.category}</TableCell>
//               <TableCell>{product.price}</TableCell>
//               <TableCell>{product.stock}</TableCell>
//               <TableCell>{new Date(product.created_at).toLocaleString()}</TableCell>
//               <TableCell>
//                 <IconButton onClick={() => handleOpenDetailDialog(product)} color="primary"><InfoIcon /></IconButton>
//                 <IconButton onClick={() => handleOpenEditDialog(product)} color="primary"><EditIcon /></IconButton>
//                 <IconButton onClick={() => setOpenDeleteDialog(true)} color="error"><DeleteIcon /></IconButton>
//               </TableCell>
//             </TableRow>
//           ))}
//         </TableBody>
//       </Table>

//       {/* Dialog de suppression */}
//       <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
//         <DialogTitle>Confirmation of Deletion</DialogTitle>
//         <DialogContent>Are you sure you want to delete this item?</DialogContent>
//         <DialogActions>
//           <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
//           <Button onClick={handleDelete} color="error">
//             {loading ? <CircularProgress size={24} color="inherit" /> : 'Delete'}
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* Dialog de modification */}
//       <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
//         <DialogTitle>Edit Product</DialogTitle>
//         <DialogContent>
//           <form onSubmit={handleEditSubmit}>
//             <Grid container spacing={2}>
//               <Grid item xs={12}>
//                 <TextField
//                   label="Product Name"
//                   fullWidth
//                   value={productName}
//                   onChange={(e) => setProductName(e.target.value)}
//                   required
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <TextField
//                   label="Price"
//                   fullWidth
//                   type="number"
//                   value={price}
//                   onChange={(e) => setPrice(e.target.value)}
//                   required
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <TextField
//                   label="Stock"
//                   fullWidth
//                   type="number"
//                   value={stock}
//                   onChange={(e) => setStock(e.target.value)}
//                   required
//                 />
//               </Grid>
//             </Grid>
//             <DialogActions>
//               <Button onClick={handleCloseEditDialog}>Cancel</Button>
//               <Button type="submit" color="primary" variant="contained">
//                 {loading ? <CircularProgress size={24} /> : 'Update'}
//               </Button>
//             </DialogActions>
//           </form>
//         </DialogContent>
//       </Dialog>

//       {/* Dialog de détails */}
//       <Dialog open={openDetailDialog} onClose={handleCloseDetailDialog} fullWidth maxWidth="md">
//         <DialogTitle>Product Details</DialogTitle>
//         <DialogContent>
//           {selectedProduct && (
//             <Grid container spacing={2}>
//               <Grid item xs={12} md={6}>
//                 <img src={imagePreviews[0]} alt="Product" style={{ width: '100%', height: '300px', objectFit: 'cover' }} />
//                 <div style={{ display: 'flex', marginTop: 10 }}>
//                   {imagePreviews.map((preview, index) => (
//                     <img
//                       key={index}
//                       src={preview}
//                       alt={`Thumbnail ${index + 1}`}
//                       style={{ width: '50px', height: '50px', cursor: 'pointer', marginRight: 10 }}
//                       onClick={() => {
//                         const updatedPreviews = [preview, ...imagePreviews.filter((_, i) => i !== index)];
//                         setImagePreviews(updatedPreviews);
//                       }}
//                     />
//                   ))}
//                 </div>
//               </Grid>
//               <Grid item xs={12} md={6}>
//                 <Typography variant="h6">{selectedProduct.product_name}</Typography>
//                 <Typography>Price: {selectedProduct.price}</Typography>
//                 <Typography>Stock: {selectedProduct.stock}</Typography>
//                 <Typography>Description: {selectedProduct.product_description}</Typography>
//               </Grid>
//             </Grid>
//           )}
//           <DialogActions>
//             <Button onClick={handleCloseDetailDialog}>Close</Button>
//           </DialogActions>
//         </DialogContent>
//       </Dialog>
//     </TableContainer>
//     </Paper>
//     </Container>
//   );
// }

// export default ViewProducts;
