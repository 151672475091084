import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Typography, CircularProgress, Alert, Grid, Box, Divider } from '@mui/material';
import Slide from '@mui/material/Slide';
import EmployeController from '../../Controllers/EmployeeController';
import { teelColor, lightOrangeColor, redColor, whiteColor, grayColor } from '../../utils/config';
import CustomTextField from '../../Components/formComponent/CustomTextField';
import CustomCancelButton from '../../Components/formComponent/CustomCancelButton';
import CustomButton from '../../Components/formComponent/CustomButton';
import CustomSelect from '../../Components/formComponent/CustomSelect';
import { error } from 'jquery';

// Transition pour les dialogues
const Transition = React.forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />);
const DeleteTransition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const EmployeeListData = () => {
  // États pour gérer les employés et les dialogues
  const [employees, setEmployees] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectagence, setSelectAgence] = useState([]);
  const [editFormData, setEditFormData] = useState({
    agencesodeId: '',
    firstname: '',
    lastname: '',
    email: '',
    mobile: '',
    username: ''
  });

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);


  // Gestion de la pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedRows = employees.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  // Chargement des employés à l'initialisation du composant

  const fetchAllEmployees = async () => {
    try {
      const data = await EmployeController.getAllEmployee();
      setEmployees(data.data.employees);
      console.log('employer recuperer::::', data.data.employees)
    } catch (error) {
      console.error('Failed to fetch employees:', error);
    }
  };

  const fetchAllAgences = async () => {
    try {
      const agence = await EmployeController.getagenceinfo();
      console.log(agence.data.data)
      setSelectAgence(agence.data.data)
    } catch (error) {
      const errorMessage = error?.response?.data?.message || "An error occurred";
      console.error(errorMessage);
    }
  };



  useEffect(() => {

    fetchAllEmployees();
    fetchAllAgences();
  }, []);



  const agencesode = selectagence.map((aganecode) => ({
    value: aganecode.id,
    label: aganecode.agent_name,

  }));


  // Formatage de la date
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  // Gestion des dialogues
  const handleOpenDetailDialog = (employee) => {
    setSelectedEmployee(employee);
    setOpenDetailDialog(true);
  };

  const handleCloseDetailDialog = () => setOpenDetailDialog(false);



  const handleCloseEditDialog = () => setOpenEditDialog(false);

  const handleOpenDeleteDialog = (employee) => {
    setSelectedEmployee(employee);
    setOpenDeleteDialog(true);
  };

  const handleOpenEditDialog = (employee) => {
    setSelectedEmployee(employee);
    setEditFormData({
      firstname: employee.firstname,
      lastname: employee.lastname,
      email: employee.email,
      mobile: employee.mobile,
      username: employee.username
    });
    setOpenEditDialog(true);
  };

  const handleCloseDeleteDialog = () => setOpenDeleteDialog(false);

  const handleDeleteEmployee = async () => {
    try {
      await EmployeController.deleteEmployee(selectedEmployee.id);
      setEmployees(employees.filter(emp => emp.id !== selectedEmployee.id));
      handleCloseDeleteDialog();
    } catch (error) {
      console.error('Failed to delete employee:', error);
    }
  };
  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditFormData({
      ...editFormData,
      [name]: value
    });
  };

  const handleSaveEdit = async () => {
    try {
      setLoading(true);
      const data = {
        agencescode: editFormData.agencesodeId,
        employeeId: selectedEmployee.id, // L'ID de l'employé sélectionné
        firstname: editFormData.firstname,
        lastname: editFormData.lastname,
        email: editFormData.email,
        mobile: editFormData.mobile,
        username: editFormData.username
      };

      console.log('data a envoyer', data)
      // Appel API pour mettre à jour les informations de l'employé
      const response = await EmployeController.AdminupdateCustomerinfo(data);

      setSuccess(response.data.message);
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 5000);
      fetchAllEmployees();
      setLoading(false);
      handleCloseEditDialog();

    } catch (error) {
      setLoading(false);
      handleCloseEditDialog()
      setError(error.response?.data?.message);
      setShowError(true);
      setTimeout(() => setShowError(false), 5000);
      console.error('Erreur lors de la récupération des données :', error);
      console.error('Failed to update employee:', error);
    }
  };



  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>

      <Typography variant="h4" sx={{ textAlign: 'center', color: teelColor }}>
        List Employees
      </Typography>
      {showError && <Alert severity="error">{error}</Alert>}
      {showSuccess && <Alert severity="success">{success}</Alert>}

      <TableContainer sx={{ maxHeight: 500 }}>
        <Table sx={{ minWidth: 550 }} aria-label="employee data table">
          <TableHead sx={{ background: teelColor, color: whiteColor }}>
            <TableRow>
              <TableCell align="center" sx={{ color: whiteColor }}>Creation date</TableCell>
              <TableCell align="center" sx={{ color: whiteColor }}>Username</TableCell>
              <TableCell align="center" sx={{ color: whiteColor }}>Firstname</TableCell>
              <TableCell align="center" sx={{ color: whiteColor }}>Lastname</TableCell>
              <TableCell align="center" sx={{ color: whiteColor }}>Email</TableCell>
              <TableCell align="center" sx={{ color: whiteColor }}>Agences</TableCell>
              <TableCell align="center" sx={{ color: whiteColor }}><strong>Action</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedRows.map((employee) => (
              <TableRow key={employee.id} sx={{ '&:last-of-type td, &:last-of-type th': { border: 0 } }}>
                <TableCell align="center">{formatDate(employee.created_at)}</TableCell>
                <TableCell align="center">{employee.username}</TableCell>
                <TableCell align="center">{employee.firstname}</TableCell>
                <TableCell align="center">{employee.lastname}</TableCell>
                <TableCell align="center">{employee.email}</TableCell>
                <TableCell align="center">
                  {employee.agences?.agent_name ? employee.agences.agent_name : 'N/A'}
                </TableCell>
                <TableCell align="center">
                  <IconButton sx={{ color: teelColor }} onClick={() => handleOpenDetailDialog(employee)}>
                    <InfoIcon />
                  </IconButton>
                  <IconButton sx={{ color: lightOrangeColor }} onClick={() => handleOpenEditDialog(employee)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton sx={{ color: redColor }} onClick={() => handleOpenDeleteDialog(employee)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={employees.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* Dialog pour les détails */}
      <Dialog open={openDetailDialog} onClose={handleCloseDetailDialog} TransitionComponent={Transition} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ backgroundColor: teelColor, color: 'white', fontWeight: 'bold', textAlign: 'center', padding: '16px' }}>
          Employee Details
        </DialogTitle>
        <DialogContent sx={{ padding: '16px' }}>
          {selectedEmployee && (
            <Box>
              <Grid container spacing={3}>
                {/* Personal Information */}
                <Grid item xs={12}>
                  <Typography variant="h6" color="textPrimary" sx={{ fontWeight: 'bold', marginBottom: '8px' }}>
                    Personal Information
                  </Typography>
                  <Divider sx={{ marginBottom: '16px' }} />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1"><strong>Full Name:</strong></Typography>
                  <Typography variant="body2" color="textSecondary">{selectedEmployee.firstname} {selectedEmployee.lastname}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1"><strong>Username:</strong></Typography>
                  <Typography variant="body2" color="textSecondary">{selectedEmployee.username}</Typography>
                </Grid>

                {/* Contact Information */}
                <Grid item xs={12} sx={{ marginTop: '24px' }}>
                  <Typography variant="h6" color="textPrimary" sx={{ fontWeight: 'bold', marginBottom: '8px' }}>
                    Contact
                  </Typography>
                  <Divider sx={{ marginBottom: '16px' }} />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1"><strong>Email:</strong></Typography>
                  <Typography variant="body2" color="textSecondary">{selectedEmployee.email}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1"><strong>Mobile:</strong></Typography>
                  <Typography variant="body2" color="textSecondary">{selectedEmployee.mobile}</Typography>
                </Grid>

                {/* Salary Information */}
                <Grid item xs={12} sx={{ marginTop: '24px' }}>
                  <Typography variant="h6" color="textPrimary" sx={{ fontWeight: 'bold', marginBottom: '8px' }}>
                    Salary
                  </Typography>
                  <Divider sx={{ marginBottom: '16px' }} />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1"><strong>Net Salary:</strong></Typography>
                  <Typography variant="body2" color="textSecondary">{selectedEmployee.net_salary} €</Typography>
                </Grid>
              </Grid>
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ padding: '16px', justifyContent: 'center' }}>
          <CustomCancelButton onClick={handleCloseDetailDialog} variant="contained" color="primary" sx={{ fontWeight: 'bold', padding: '6px 24px' }}>
            Cancel
          </CustomCancelButton>
        </DialogActions>
      </Dialog>


      {/* Dialog pour la suppression */}
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog} TransitionComponent={DeleteTransition}>
        <DialogTitle>Delete Employee</DialogTitle>
        <DialogContent>Are you sure you want to delete this employee?</DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">Cancel</Button>
          <Button onClick={handleDeleteEmployee} color="error">Delete</Button>
        </DialogActions>
      </Dialog>


      {/* Dialog pour la modification (à implémenter selon vos besoins) */}
      <Dialog open={openEditDialog} onClose={handleCloseEditDialog} TransitionComponent={Transition}>
        <DialogTitle>Edit Employee</DialogTitle>
        <DialogContent>
          {selectedEmployee && (
            <form>
              <CustomTextField
                label="First Name"
                name="firstname"
                fullWidth
                value={editFormData.firstname}
                onChange={handleEditChange}
                margin="normal"
              />
              <CustomTextField
                label="Last Name"
                name="lastname"
                fullWidth
                value={editFormData.lastname}
                onChange={handleEditChange}
                margin="normal"
              />
              <CustomTextField
                label="Email"
                name="email"
                fullWidth
                value={editFormData.email}
                onChange={handleEditChange}
                margin="normal"
              />
              <CustomTextField
                label="Mobile"
                name="mobile"
                fullWidth
                value={editFormData.mobile}
                onChange={handleEditChange}
                margin="normal"
              />
              <CustomTextField
                label="Username"
                name="username"
                fullWidth
                value={editFormData.username}
                onChange={handleEditChange}
                margin="normal"
              />
              <CustomSelect
                name="agencesodeId"
                label="Agence"
                value={editFormData.agencesodeId}
                onChange={handleEditChange}
                options={agencesode}
              />

            </form>
          )}
        </DialogContent>
        <DialogActions>
          <CustomCancelButton onClick={handleCloseEditDialog} color="primary">Cancel</CustomCancelButton>
          <CustomButton sx={{ width: 200 }} onClick={handleSaveEdit} color="primary">
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Save'}
          </CustomButton>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default EmployeeListData;

