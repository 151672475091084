// ** MUI Imports
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// ** Utils Imports
import { teelColor } from '../../../utils/config';
import CustomersorderData from '../../tables/Customerorder';

const CustomerorderListe = () => {
  return (

    <Grid container spacing={6}>
      <Grid item xs={12}>
        
      </Grid>
      <Grid item xs={12}>
        <CustomersorderData></CustomersorderData>
      </Grid>
    </Grid>

    
  );
};

export default CustomerorderListe;
