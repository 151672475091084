// src/components/CustomDeleteButton.js
import React from 'react';
import { Button } from '@mui/material';
import { teelColor,redColor } from '../../utils/config'; // Assurez-vous que le chemin est correct

const CustomDeleteButton = ({ onClick, children, ...props }) => {
  return (
    <Button
      onClick={onClick}
      {...props}
      sx={{
        backgroundColor: 'transparent', // Fond transparent pour le bouton de suppression
        color: redColor, // Couleur du texte
        border: `1px solid ${redColor}`, // Bordure de la même couleur
        '&:hover': {
          backgroundColor: redColor, // Fond lorsque le bouton est survolé
          color: '#fff', // Couleur du texte lorsque survolé
        },
        padding: '8px 16px', // Padding pour le bouton
      }}
    >
      {children}
    </Button>
  );
};

export default CustomDeleteButton;
