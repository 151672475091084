import React, { useEffect, useState } from 'react';
import { Grid, Card, Typography, Box, Container, CircularProgress } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import LoginController from '../../Controllers/LoginController';
import { iconColor } from '../../utils/config';
import { dashcolor } from '../../utils/config';

const Dashboard = () => {
  const [CountAdmin, setCountAdmin] = useState(0);
  const [CountEmployer, setCountEmployer] = useState(0);
  const [CountVendor, setCountVendor] = useState(0);
  const [loading, setLoading] = useState(true); // État de chargement

  useEffect(() => {
    const fetchCountUser = async () => {
      try {
        const response = await LoginController.usercountdata();
        console.log('Full response:', response); // Affichez la réponse complète

        // Vérifiez si la réponse est réussie et que les données existent
        if (response.data.success && response.data.data) {
          const { countAdmin = 0, countEmployer = 0, countVendor = 0 } = response.data.data;

          // Mettre à jour les états
          setCountAdmin(countAdmin);
          setCountEmployer(countEmployer);
          setCountVendor(countVendor);
        } else {
          console.error('Failed to fetch user counts: ', response.data.message);
        }
      } catch (error) {
        console.error("Error fetching user count data:", error);
      } finally {
        setLoading(false); // Fin du chargement
      }
    };

    fetchCountUser();
  }, []);

  return (
    <Container maxWidth="lg" sx={{ marginTop: 10, overflow: 'hidden' }}>
      <Box sx={{ padding: 3 }}>
        <Grid container spacing={3}>
          {/* Admin Count Card */}
          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ padding: 2, textAlign: 'center' }}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <PeopleIcon sx={{ fontSize: 30, color: iconColor }} />
                {loading ? (
                  <CircularProgress size={30} />
                ) : (
                  <Typography variant="h6">{CountAdmin}</Typography>
                )}
                <Typography variant="subtitle1">Admin</Typography>
              </Box>
            </Card>
          </Grid>

          {/* Employer Count Card */}
          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ padding: 2, textAlign: 'center' }}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <PeopleIcon sx={{ fontSize: 30, color: iconColor }} />
                {loading ? (
                  <CircularProgress size={30} />
                ) : (
                  <Typography variant="h6">{CountEmployer}</Typography>
                )}
                <Typography variant="subtitle1">Employees</Typography>
              </Box>
            </Card>
          </Grid>

          {/* Vendor Count Card */}
          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ padding: 2, textAlign: 'center' }}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <PeopleIcon sx={{ fontSize: 30, color: iconColor }} />
                {loading ? (
                  <CircularProgress size={30} />
                ) : (
                  <Typography variant="h6">{CountVendor}</Typography>
                )}
                <Typography variant="subtitle1">Vendors</Typography>
              </Box>
            </Card>
          </Grid>

          {/* Total Orders Card */}
          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ padding: 2, textAlign: 'center' }}>
              <Typography variant="h5">Total Orders</Typography>
              <Typography variant="h4">$24,895</Typography>
              <Typography variant="subtitle1" sx={{color:dashcolor}}>Compared to $84,325 last year</Typography>
            </Card>
          </Grid>

          {/* Additional Total Orders Card */}
          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ padding: 2, textAlign: 'center' }}>
              <Typography variant="h5">Total Orders</Typography>
              <Typography variant="h4">$24,895</Typography>
              <Typography variant="subtitle1" sx={{color:dashcolor}}>Compared to $84,325 last year</Typography>
            </Card>
          </Grid>

          {/* Total Profit Card */}
          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ padding: 2, textAlign: 'center' }}>
              <Typography variant="h5">Total Profit</Typography>
              <Typography variant="h4">$25.6k</Typography>
              <Typography variant="subtitle1" sx={{color:dashcolor}}>Weekly Profit</Typography>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Dashboard;

