import React, { useContext } from 'react';
import { UserContext } from '../@core/Provider/UserContext'; // Ensure correct path
import HomeOutline from '@mui/icons-material/Home';
import AccountCircle from '@mui/icons-material/AccountCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AppsIcon from '@mui/icons-material/Apps';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PeopleIcon from '@mui/icons-material/People';
import StorefrontIcon from '@mui/icons-material/Storefront';
import PaymentIcon from '@mui/icons-material/Payment';

const SidebarNavigation = () => {
  const { userInfo } = useContext(UserContext);
  const role = userInfo?.getUserRole();

  const commonNavigation = [
    {
      title: 'Dashboard',
      icon: <HomeOutline />,
      path: '/'
    },
  ];

  const navItems = [
    ...(role === 'admin'
      ? [
          ...commonNavigation,
          {
            sectionTitle: 'Admin Interface'
          },
          
          {
            title: 'Add Vendor & Employee',
            icon: <PeopleIcon />,
            path: '/addemployvendor'
          },
          {
            title: 'Manage Vendors',
            icon: <AccountCircle />,
            path: '/UserVendorListe'
          },
          {
            title: 'Manage Employees',
            icon: <AccountCircle />,
            path: '/employListe'
          },
          {
            title: 'Manage Agencies',
            icon: <PeopleIcon />,
            path: '/Addagency'
          },
          {
            title: 'Manage Categories',
            icon: <PeopleIcon />,
            path: '/AddCategory'
          },
          
          {
            title: 'Vendor Product',
            icon: <StorefrontIcon />,
            path: '/vendorproductListe'
          },
          {
            title: 'Vendor Order',
            icon: <AssignmentIcon />,
            path: '/Customerorderdata'
          },
          {
            title: 'Payments',
            icon: <PaymentIcon />,
            path: '/PaiementListe'
          },
        ]
      : []),

    ...(role === 'client'
      ? [
          ...commonNavigation,
          {
            sectionTitle: 'User Interface'
          },
          {
            title: 'Account Settings',
            icon: <AccountCircle />,
            path: '/index'
          },
        ]
      : []),

    ...(role === 'vendor'
      ? [
          ...commonNavigation,
          {
            sectionTitle: 'Vendor Interface'
          },
          {
            title: 'Add Products',
            icon: <AddCircleIcon />,
            path: '/add-product',
          },
          {
            title: 'My Products',
            icon: <AppsIcon />,
            path: '/products',
          },
          {
            title: 'My Orders',
            icon: <AssignmentIcon />,
            path: '/orders',
          },
        ]
      : []),
  ];

  return navItems;
};

export default SidebarNavigation;




// import React, { useContext } from 'react';
// import { UserContext } from '../@core/Provider/UserContext'; // Assurez-vous que le chemin est correct
// import HomeOutline from '@mui/icons-material/Home';
// import AccountCogOutline from '@mui/icons-material/AccountCircle';
// import AddCircleIcon from '@mui/icons-material/AddCircle';
// import AppsIcon from '@mui/icons-material/Apps';
// import AssignmentIcon from '@mui/icons-material/Assignment';

// const SidebarNavigation = () => {
//   const { userInfo } = useContext(UserContext);
//   const role = userInfo?.getUserRole();
//   const commonNavigation = [
//     {
//       title: 'Dashboard',
//       icon: <HomeOutline />,
//       path: '/'
//     },
//   ];

//   const navItems = [
//     ...(role === 'admin'
//       ? [
//           ...commonNavigation,
//           {
//             sectionTitle: 'Admin Interface'
//           },
//           {
//             title: 'Add Vendor & Employee',
//             icon: <AccountCogOutline />,
//             path: '/addemployvendor'
//           },
//           {
//             title: 'User Vendor',
//             icon: <AccountCogOutline />,
//             path: '/UserVendorListe'
//           },
//           {
//             title: 'User Client',
//             icon: <AccountCogOutline />,
//             path: '/employListe'
//           },
//           {
//             title: 'Vendor Product',
//             icon: <AccountCogOutline />,
//             path: '/vendorproductListe'
//           },
//           {
//             title: 'Vendor Order',
//             icon: <AccountCogOutline />,
//             path: '/Customerorderdata'
//           },
//           {
//             title: 'Paiements',
//             icon: <AccountCogOutline />,
//             path: '/PaiementListe'
//           },
          
        

//         ]
//       : []),

//     ...(role === 'client'
//       ? [
//           ...commonNavigation,
//           {
//             sectionTitle: 'User Interface'
//           },
//           {
//             title: 'Account Settings',
//             icon: <AccountCogOutline />,
//             path: '/index'
//           },
//         ]
//       : []),

//     ...(role === 'vendor'
//       ? [
//           ...commonNavigation,
//           {
//             sectionTitle: 'Vendor Interface'
//           },
//           {
//             title: 'add products',
//             icon: <AddCircleIcon />,
//             path: '/add-product',
//           },
//           {
//             title: 'My Products',
//             icon: <AppsIcon />,
//             path: '/products',
//           },
//           {
//             title: 'My orders',
//             icon: <AssignmentIcon />,
//             path: '/orders',
//           },
//         ]
//       : []),
//   ];

//   return navItems;
// };

// export default SidebarNavigation;


