// src/components/CustomButton.js
import React from 'react';
import { Button } from '@mui/material';
import { teelColor } from '../../utils/config';

function CustomButton({ children, variant = "contained", fullWidth = true, sx = {}, ...props }) {
  return (
    <Button
      variant={variant}
      fullWidth={fullWidth}
      {...props}
      sx={{
        backgroundColor: teelColor,
        padding: '8px 16px',
        color: '#fff',
        transition: 'transform 0.1s ease-in-out',
        '&:hover': {
          backgroundColor: teelColor, // Maintient la couleur survolée
        },
        '&:active': {
          transform: 'scale(0.95)', // Réduit légèrement pour l'effet d'enfoncement
        },
        ...sx,
      }}
    >
      {children}
    </Button>
  );
}

export default CustomButton;
