import React, { useState, useEffect } from 'react';
import {
  Paper,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  IconButton,
  Alert,
  CircularProgress,
  Typography,
  Grid,
  Box,
  Divider,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Slide,
  Pagination
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import VendorController from '../../../Controllers/VendorControllers';
import EmployeController from '../../../Controllers/EmployeeController';
import { teelColor, lightOrangeColor, redColor, whiteColor } from '../../../utils/config';
import CustomTextField from '../../../Components/formComponent/CustomTextField';
import CustomCancelButton from '../../../Components/formComponent/CustomCancelButton';
import CustomButton from '../../../Components/formComponent/CustomButton';

const Transition = React.forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />);
const DeletTransition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
const VendorsListData = () => {
  const [employees, setEmployees] = useState([]);
  const [page, setPage] = useState(1); // Page actuelle (commence à 1 pour correspondre à la pagination de MUI)
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [editFormData, setEditFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    mobile: '',
    username: '',
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  const fetchAllEmployee = async () => {
    try {
      const data = await VendorController.getAllVendor();
      setEmployees(data.data.employees);
    } catch (error) {
      console.error('Failed to fetch employees:', error);
    }
  };

  useEffect(() => {
    fetchAllEmployee();
  }, []);

  const handleEditChange = (e) => {
    setEditFormData({
      ...editFormData,
      [e.target.name]: e.target.value,
    });
  };

  const filteredvendors = employees.filter((employee) =>
    employee.firstname.toLowerCase().includes(searchQuery.toLowerCase()) ||
    employee.lastname.toLowerCase().includes(searchQuery.toLowerCase()) ||
    employee.username.toLowerCase().includes(searchQuery.toLowerCase()) ||
    employee.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
    employee.mobile.toLowerCase().includes(searchQuery.toLowerCase())
   
  );

  const paginatedRows = filteredvendors.slice((page - 1) * rowsPerPage, page * rowsPerPage);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const handleOpenDetailDialog = (employee) => {
    setSelectedEmployee(employee);
    setOpenDetailDialog(true);
  };

  const handleCloseDetailDialog = () => {
    setOpenDetailDialog(false);
  };

  const handleOpenEditDialog = (employee) => {
    setSelectedEmployee(employee);
    setEditFormData({
      firstname: employee.firstname,
      lastname: employee.lastname,
      email: employee.email,
      mobile: employee.mobile,
      username: employee.username,
    });
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleOpenDeleteDialog = (employee) => {
    setSelectedEmployee(employee);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleDeleteEmployee = async () => {
    try {
      await VendorController.deleteEmployee(selectedEmployee.id);
      setEmployees(employees.filter(emp => emp.id !== selectedEmployee.id));
      handleCloseDeleteDialog();
    } catch (error) {
      console.error('Failed to delete employee:', error);
    }
  };

  const handleSaveEdit = async () => {
    try {
      setLoading(true);
      const data = {
        employeeId: selectedEmployee.id,
        firstname: editFormData.firstname,
        lastname: editFormData.lastname,
        email: editFormData.email,
        mobile: editFormData.mobile,
        username: editFormData.username
      };
      const response = await EmployeController.Adminupdatevendorinfo(data);
      setSuccess(response.data.message);
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 5000);
      fetchAllEmployee();
      setLoading(false);
      handleCloseEditDialog();
    } catch (error) {
      setLoading(false);
      handleCloseEditDialog();
      setError(error.response?.data?.message);
      setShowError(true);
      setTimeout(() => setShowError(false), 5000);
    }
  };
  const getStatusVendor = (status) => {
        // Définition des constantes pour les statuts
        const STATUT_BLOQUE = 'blocked';
        const STATUT_ACTIF = 'active';
    
        switch (status) {
          case 0:
            return STATUT_BLOQUE;
          case 1:
            return STATUT_ACTIF;
          default:
            return 'Unknown status';
        }
      };

  return (
    <Container maxWidth="ml" sx={{ marginTop: '8px' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ padding: '10px' }}>
        <Grid sx={{ flex: 1, maxWidth: '400px', marginRight: '10px' }}>
        <CustomTextField
            label="Search Vendors"
            variant="outlined"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            fullWidth
            sx={{ height: '40px' }} 
          />
        </Grid>
        {/* <CustomButton sx={{ width: "200px" }} variant="contained" color="primary">Add Vendor</CustomButton> */}
      </Box>
      <Paper sx={{ marginTop: 3, padding: 2, overflow: 'hidden', boxShadow: 'none' }}>
        {showError && <Alert severity="error">{error}</Alert>}
        {showSuccess && <Alert severity="success">{success}</Alert>}
        <TableContainer>
          <Table >
            <TableHead sx={{ background: teelColor, color: whiteColor }}>
              <TableRow>
                <TableCell sx={{ color: whiteColor }} align="center">Creation date</TableCell>
                <TableCell sx={{ color: whiteColor }} align="center">Username</TableCell>
                <TableCell sx={{ color: whiteColor }} align="center">Firstname</TableCell>
                <TableCell sx={{ color: whiteColor }} align="center">Lastname</TableCell>
                <TableCell sx={{ color: whiteColor }} align="center">Email</TableCell>
                <TableCell sx={{ color: whiteColor }} align="center">Mobile</TableCell>
                <TableCell sx={{ color: whiteColor }} align="center">Status</TableCell>
                <TableCell sx={{ color: whiteColor }} align="center"><strong>Action</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {employees.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={8} align="center">No vendor found.</TableCell>
                </TableRow>
              ) : (
                paginatedRows.map((employee) => (
                  <TableRow key={employee.id}>
                    
                    <TableCell sx={{ whiteSpace: 'nowrap' }} align="center">{formatDate(employee.created_at)}</TableCell>
                    <TableCell align="center">{employee.username}</TableCell>
                    <TableCell align="center">{employee.firstname}</TableCell>
                    <TableCell align="center">{employee.lastname}</TableCell>
                    <TableCell align="center">{employee.email}</TableCell>
                    <TableCell align="center">{employee.mobile}</TableCell>
                    <TableCell  align="center">
                      <span style={{ color: getStatusVendor(employee.status) === 'blocked' ? 'red' : 'green' }}>
                        {getStatusVendor(employee.status)}
                      </span>
                    </TableCell>
                    <TableCell sx={{ whiteSpace: 'nowrap' }} align="center">
                      <IconButton sx={{ color: teelColor }} onClick={() => handleOpenDetailDialog(employee)}>
                        <InfoIcon />
                      </IconButton>
                      <IconButton sx={{ color: lightOrangeColor }} onClick={() => handleOpenEditDialog(employee)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton sx={{ color: redColor }} onClick={() => handleOpenDeleteDialog(employee)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
          <Pagination
            count={Math.ceil(filteredvendors.length / rowsPerPage)}
            page={page}
            onChange={handleChangePage}
            color="primary"
          />
        </Box>
      </Paper>

      <Dialog open={openDetailDialog} onClose={handleCloseDetailDialog} TransitionComponent={Transition} maxWidth="sm" fullWidth>
          <DialogTitle sx={{ backgroundColor: teelColor, color: 'white', fontWeight: 'bold', textAlign: 'center' }}>
            Vendor Details
          </DialogTitle>
          <DialogContent sx={{ padding: '24px' }}>
            {selectedEmployee && (
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6" color="textPrimary">
                      Personal Information
                    </Typography>
                    <Divider sx={{ marginBottom: '12px', marginTop: '4px' }} />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1"><strong>Full Name:</strong></Typography>
                    <Typography variant="body2" color="textSecondary">{selectedEmployee.firstname} {selectedEmployee.lastname}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1"><strong>Username:</strong></Typography>
                    <Typography variant="body2" color="textSecondary">{selectedEmployee.username}</Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: '16px' }}>
                    <Typography variant="h6" color="textPrimary">
                      Contact
                    </Typography>
                    <Divider sx={{ marginBottom: '12px', marginTop: '4px' }} />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1"><strong>Email:</strong></Typography>
                    <Typography variant="body2" color="textSecondary">{selectedEmployee.email}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1"><strong>Mobile:</strong></Typography>
                    <Typography variant="body2" color="textSecondary">{selectedEmployee.mobile}</Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: '16px' }}>
                    <Typography variant="h6" color="textPrimary">
                      Salary
                    </Typography>
                    <Divider sx={{ marginBottom: '12px', marginTop: '4px' }} />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1"><strong>Net Salary:</strong></Typography>
                    <Typography variant="body2" color="textSecondary">{selectedEmployee.net_salary} €</Typography>
                  </Grid>
                </Grid>
              </Box>
            )}
          </DialogContent>
          <DialogActions sx={{ padding: '16px' }}>
            <CustomCancelButton onClick={handleCloseDetailDialog} variant="contained" color="primary" sx={{ fontWeight: 'bold' }}>
              Close
            </CustomCancelButton>
          </DialogActions>
        </Dialog>


        {/* Dialog pour la suppression */}
        <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog} TransitionComponent={DeletTransition}>
          <DialogTitle>Delete Vendor</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this vendor?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog} color="primary">Cancel</Button>
            <Button onClick={handleDeleteEmployee} color="error">Delete</Button>
          </DialogActions>
        </Dialog>

        {/* Dialog pour la modification (à implémenter selon votre besoin) */}
        <Dialog open={openEditDialog} onClose={handleCloseEditDialog} TransitionComponent={Transition}>
          <DialogTitle>Edit Vendor</DialogTitle>
          <DialogContent>
            {selectedEmployee && (
              <form>
                <CustomTextField
                  label="First Name"
                  name="firstname"
                  fullWidth
                  value={editFormData.firstname}
                  onChange={handleEditChange}
                  margin="normal"
                />
                <CustomTextField
                  label="Last Name"
                  name="lastname"
                  fullWidth
                  value={editFormData.lastname}
                  onChange={handleEditChange}
                  margin="normal"
                />
                <CustomTextField
                  label="Email"
                  name="email"
                  fullWidth
                  value={editFormData.email}
                  onChange={handleEditChange}
                  margin="normal"
                />
                <CustomTextField
                  label="Mobile"
                  name="mobile"
                  fullWidth
                  value={editFormData.mobile}
                  onChange={handleEditChange}
                  margin="normal"
                />
                <CustomTextField
                  label="Username"
                  name="username"
                  fullWidth
                  value={editFormData.username}
                  onChange={handleEditChange}
                  margin="normal"
                />
              </form>
            )}
          </DialogContent>
          <DialogActions>
            <CustomCancelButton onClick={handleCloseEditDialog} color="primary">Cancel</CustomCancelButton>
            {/* <CustomButton  color="primary">Save</CustomButton> */}
            <CustomButton sx={{ width: 200 }} onClick={handleSaveEdit} color="primary">
              {loading ? <CircularProgress size={24} color="inherit" /> : 'Save'}
            </CustomButton>
          </DialogActions>
        </Dialog>
    </Container>
  );
};

export default VendorsListData;








// import React, { useState, useEffect } from 'react';
// import Paper from '@mui/material/Paper';
// import Table from '@mui/material/Table';
// import TableRow from '@mui/material/TableRow';
// import TableHead from '@mui/material/TableHead';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TablePagination from '@mui/material/TablePagination';
// import IconButton from '@mui/material/IconButton';
// import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';
// import InfoIcon from '@mui/icons-material/Info';
// import { Alert,CircularProgress,Typography,Grid,Box,Divider } from '@mui/material';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogTitle from '@mui/material/DialogTitle';
// import Button from '@mui/material/Button';
// import Slide from '@mui/material/Slide';
// import VendorController from '../../../Controllers/VendorControllers';
// import EmployeController from '../../../Controllers/EmployeeController';
// import { teelColor, lightOrangeColor, redColor, whiteColor } from '../../../utils/config';
// import CustomTextField from '../../../Components/formComponent/CustomTextField';

// import CustomCancelButton from '../../../Components/formComponent/CustomCancelButton';
// import CustomButton from '../../../Components/formComponent/CustomButton';

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="down" ref={ref} {...props} />;
// });

// const DeletTransition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

// const VendorsListData = () => {
//   const [employees, setEmployees] = useState([]);
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(5);
//   const [openDetailDialog, setOpenDetailDialog] = useState(false);
//   const [openEditDialog, setOpenEditDialog] = useState(false);
//   const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
//   const [selectedEmployee, setSelectedEmployee] = useState(null);
//   const [editFormData, setEditFormData] = useState({
//     firstname: '',
//     lastname: '',
//     email: '',
//     mobile: '',
//     username: '',
//   });
//   const [error, setError] = useState('');
//   const [success, setSuccess] = useState('');
//   const [showError, setShowError] = useState(false);
//   const [showSuccess, setShowSuccess] = useState(false);
//   const [loading, setLoading] = useState(false);


//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   const paginatedRows = employees.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

//   const fetchAllEmployee = async () => {
//     try {
//       const data = await VendorController.getAllVendor();
//       setEmployees(data.data.employees);
//       console.log(data.data.employees)
//     } catch (error) {
//       console.error('Failed to fetch employees:', error);
//     }
//   };

//   useEffect(() => {
   
//     fetchAllEmployee();
//   }, []);
//   const handleEditChange = (e) => {
//     setEditFormData({
//       ...editFormData,
//       [e.target.name]: e.target.value,
//     });
//   };

//   const formatDate = (dateString) => {
//     const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
//     return new Date(dateString).toLocaleDateString('en-US', options);
//   };

//   const handleOpenDetailDialog = (employee) => {
//     setSelectedEmployee(employee);
//     setOpenDetailDialog(true);
//   };

//   const handleCloseDetailDialog = () => {
//     setOpenDetailDialog(false);
//   };

//   const handleOpenEditDialog = (employee) => {
//     setSelectedEmployee(employee);
//     setEditFormData({
//       firstname: employee.firstname,
//       lastname: employee.lastname,
//       email: employee.email,
//       mobile: employee.mobile,
//       username: employee.username,
//     });
//     setOpenEditDialog(true);
//   };

//   const handleCloseEditDialog = () => {
//     setOpenEditDialog(false);
//   };

//   const handleOpenDeleteDialog = (employee) => {
//     setSelectedEmployee(employee);
//     setOpenDeleteDialog(true);
//   };

//   const handleCloseDeleteDialog = () => {
//     setOpenDeleteDialog(false);
//   };

//   const handleDeleteEmployee = async () => {
//     try {
//       await VendorController.deleteEmployee(selectedEmployee.id); // Remplacez par la méthode de suppression appropriée
//       setEmployees(employees.filter(emp => emp.id !== selectedEmployee.id)); // Met à jour la liste des employés
//       handleCloseDeleteDialog();
//     } catch (error) {
//       console.error('Failed to delete employee:', error);
//     }
//   };

//   const getStatusVendor = (status) => {
//     // Définition des constantes pour les statuts
//     const STATUT_BLOQUE = 'blocked';
//     const STATUT_ACTIF = 'active';

//     switch (status) {
//       case 0:
//         return STATUT_BLOQUE;
//       case 1:
//         return STATUT_ACTIF;
//       default:
//         return 'Unknown status';
//     }
//   };

//   const handleSaveEdit = async () => {
//     try {
//       setLoading(true);
//       const data = {
//         employeeId: selectedEmployee.id, // L'ID de l'employé sélectionné
//         firstname: editFormData.firstname,
//         lastname: editFormData.lastname,
//         email: editFormData.email,
//         mobile: editFormData.mobile,
//         username: editFormData.username
//       };

//       // Appel API pour mettre à jour les informations de l'employé
//       const response = await EmployeController.Adminupdatevendorinfo(data);

//       setSuccess(response.data.message);
//       setShowSuccess(true);
//       setTimeout(() => setShowSuccess(false), 5000);
//       fetchAllEmployee();
//       setLoading(false);
//       handleCloseEditDialog();

//     } catch (error) {
//       setLoading(false);
//       handleCloseEditDialog()
//       setError(error.response?.data?.message);
//       setShowError(true);
//       setTimeout(() => setShowError(false), 5000);
//       console.error('Erreur lors de la récupération des données :', error);
//       console.error('Failed to update employee:', error);
//     }
//   };



//   return (
//     <Paper sx={{ width: '100%', overflow: 'hidden' }}>
//        <Typography variant="h4" sx={{ textAlign: 'center', color: teelColor }}>
//         List Vendor
//       </Typography>
//        {showError && <Alert severity="error">{error}</Alert>}
//        {showSuccess && <Alert severity="success">{success}</Alert>}
//       <TableContainer sx={{ maxHeight: 500 }}>
//         <Table sx={{ minWidth: 550 }} aria-label="employee data table">
//           <TableHead sx={{ background: teelColor, color: whiteColor }}>
//             <TableRow>
//               <TableCell sx={{ color: whiteColor }} align="center">Creation date</TableCell>
//               <TableCell sx={{ color: whiteColor }} align="center">Username</TableCell>
//               <TableCell sx={{ color: whiteColor }} align="center">Firstname</TableCell>
//               <TableCell sx={{ color: whiteColor }} align="center">Lastname</TableCell>
//               <TableCell sx={{ color: whiteColor }} align="center">Email</TableCell>
//               <TableCell sx={{ color: whiteColor }} align="center">Mobile</TableCell>
//               <TableCell sx={{ color: whiteColor }} align="center">status</TableCell>
//               <TableCell sx={{ color: whiteColor }} align="center"><strong>Action</strong></TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {employees.length === 0 ? (
//               <TableRow>
//                 <TableCell colSpan={6} align="center">No vendor found.</TableCell>
//               </TableRow>
//             ) : (
//               paginatedRows.map((employee) => (
//                 <TableRow
//                   key={employee.id}
//                   sx={{ '&:last-of-type td, &:last-of-type th': { border: 0 } }}
//                 >
//                   <TableCell align="center">{formatDate(employee.created_at)}</TableCell>
//                   <TableCell align="center">{employee.username}</TableCell>
//                   <TableCell align="center">{employee.firstname}</TableCell>
//                   <TableCell align="center">{employee.lastname}</TableCell>
//                   <TableCell align="center">{employee.email}</TableCell>
//                   <TableCell align="center">{employee.mobile}</TableCell>
//                   <TableCell align="center">
//                     {getStatusVendor(employee.status) === 'blocked' ? (
//                       <span style={{ color: 'red' }}>{getStatusVendor(employee.status)}</span>
//                     ) : (
//                       <span style={{ color: 'green' }}>{getStatusVendor(employee.status)}</span>
//                     )}
//                   </TableCell>
//                   <TableCell align="center">
//                     <IconButton sx={{ color: teelColor }} aria-label="info" onClick={() => handleOpenDetailDialog(employee)}>
//                       <InfoIcon />
//                     </IconButton>
//                     <IconButton sx={{ color: lightOrangeColor }} aria-label="edit" onClick={() => handleOpenEditDialog(employee)}>
//                       <EditIcon />
//                     </IconButton>
//                     <IconButton sx={{ color: redColor }} aria-label="delete" onClick={() => handleOpenDeleteDialog(employee)}>
//                       <DeleteIcon />
//                     </IconButton>
//                   </TableCell>
//                 </TableRow>
//               ))
//             )}
//           </TableBody>
//         </Table>
//       </TableContainer>
//       <TablePagination
//         rowsPerPageOptions={[10, 25, 100]}
//         component="div"
//         count={employees.length}
//         rowsPerPage={rowsPerPage}
//         page={page}
//         onPageChange={handleChangePage}
//         onRowsPerPageChange={handleChangeRowsPerPage}
//       />

//       {/* Dialog pour les détails */}
//       <Dialog open={openDetailDialog} onClose={handleCloseDetailDialog} TransitionComponent={Transition} maxWidth="sm" fullWidth>
//     <DialogTitle sx={{ backgroundColor: teelColor, color: 'white', fontWeight: 'bold', textAlign: 'center' }}>
//         Vendor Details
//     </DialogTitle>
//     <DialogContent sx={{ padding: '24px' }}>
//         {selectedEmployee && (
//             <Box>
//                 <Grid container spacing={2}>
//                     <Grid item xs={12}>
//                         <Typography variant="h6" color="textPrimary">
//                             Personal Information
//                         </Typography>
//                         <Divider sx={{ marginBottom: '12px', marginTop: '4px' }} />
//                     </Grid>
//                     <Grid item xs={6}>
//                         <Typography variant="body1"><strong>Full Name:</strong></Typography>
//                         <Typography variant="body2" color="textSecondary">{selectedEmployee.firstname} {selectedEmployee.lastname}</Typography>
//                     </Grid>
//                     <Grid item xs={6}>
//                         <Typography variant="body1"><strong>Username:</strong></Typography>
//                         <Typography variant="body2" color="textSecondary">{selectedEmployee.username}</Typography>
//                     </Grid>
//                     <Grid item xs={12} sx={{ marginTop: '16px' }}>
//                         <Typography variant="h6" color="textPrimary">
//                             Contact
//                         </Typography>
//                         <Divider sx={{ marginBottom: '12px', marginTop: '4px' }} />
//                     </Grid>
//                     <Grid item xs={6}>
//                         <Typography variant="body1"><strong>Email:</strong></Typography>
//                         <Typography variant="body2" color="textSecondary">{selectedEmployee.email}</Typography>
//                     </Grid>
//                     <Grid item xs={6}>
//                         <Typography variant="body1"><strong>Mobile:</strong></Typography>
//                         <Typography variant="body2" color="textSecondary">{selectedEmployee.mobile}</Typography>
//                     </Grid>
//                     <Grid item xs={12} sx={{ marginTop: '16px' }}>
//                         <Typography variant="h6" color="textPrimary">
//                             Salary
//                         </Typography>
//                         <Divider sx={{ marginBottom: '12px', marginTop: '4px' }} />
//                     </Grid>
//                     <Grid item xs={6}>
//                         <Typography variant="body1"><strong>Net Salary:</strong></Typography>
//                         <Typography variant="body2" color="textSecondary">{selectedEmployee.net_salary} €</Typography>
//                     </Grid>
//                 </Grid>
//             </Box>
//         )}
//     </DialogContent>
//     <DialogActions sx={{ padding: '16px' }}>
//         <CustomCancelButton onClick={handleCloseDetailDialog} variant="contained" color="primary" sx={{ fontWeight: 'bold' }}>
//             Close
//         </CustomCancelButton>
//     </DialogActions>
// </Dialog>


//       {/* Dialog pour la suppression */}
//       <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog} TransitionComponent={DeletTransition}>
//         <DialogTitle>Delete Vendor</DialogTitle>
//         <DialogContent>
//           Are you sure you want to delete this vendor?
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCloseDeleteDialog} color="primary">Cancel</Button>
//           <Button onClick={handleDeleteEmployee} color="error">Delete</Button>
//         </DialogActions>
//       </Dialog>

//       {/* Dialog pour la modification (à implémenter selon votre besoin) */}
//       <Dialog open={openEditDialog} onClose={handleCloseEditDialog} TransitionComponent={Transition}>
//         <DialogTitle>Edit Vendor</DialogTitle>
//         <DialogContent>
//           {selectedEmployee && (
//             <form>
//               <CustomTextField
//                 label="First Name"
//                 name="firstname"
//                 fullWidth
//                 value={editFormData.firstname}
//                 onChange={handleEditChange}
//                 margin="normal"
//               />
//               <CustomTextField
//                 label="Last Name"
//                 name="lastname"
//                 fullWidth
//                 value={editFormData.lastname}
//                 onChange={handleEditChange}
//                 margin="normal"
//               />
//               <CustomTextField
//                 label="Email"
//                 name="email"
//                 fullWidth
//                 value={editFormData.email}
//                 onChange={handleEditChange}
//                 margin="normal"
//               />
//               <CustomTextField
//                 label="Mobile"
//                 name="mobile"
//                 fullWidth
//                 value={editFormData.mobile}
//                 onChange={handleEditChange}
//                 margin="normal"
//               />
//               <CustomTextField
//                 label="Username"
//                 name="username"
//                 fullWidth
//                 value={editFormData.username}
//                 onChange={handleEditChange}
//                 margin="normal"
//               />
//             </form>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <CustomCancelButton onClick={handleCloseEditDialog} color="primary">Cancel</CustomCancelButton>
//           {/* <CustomButton  color="primary">Save</CustomButton> */}
//           <CustomButton sx={{ width: 200 }} onClick={handleSaveEdit} color="primary">
//             {loading ? <CircularProgress size={24} color="inherit" /> : 'Save'}
//           </CustomButton>
//         </DialogActions>
//       </Dialog>
//     </Paper>
//   );
// };

// export default VendorsListData;


