import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Box, Container, Typography, Snackbar, Alert, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, CircularProgress, Slide } from '@mui/material';
import CustomButton from '../../../Components/formComponent/CustomButton';
import CustomTextField from '../../../Components/formComponent/CustomTextField';
import { teelColor, lightOrangeColor, redColor, grayColor, whiteColor } from '../../../utils/config';
import EmployeController from '../../../Controllers/EmployeeController';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CustomCancelButton from '../../../Components/formComponent/CustomCancelButton';
import CustomDeleteButton from '../../../Components/formComponent/CustomDeleteButton';
import Pagination from '@mui/material/Pagination';
import VendorController from '../../../Controllers/VendorControllers';

const Transition = React.forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />);
const DeleteTransition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const AddCategory = () => {
  const [categorieName, setcategorieName] = useState('');
  const [description, setDescription] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [openDialog, setOpenDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [categories, setCategories] = useState([])
  const [selectedCategory, setselectedCategory] = useState(null);
  const [editcategoryName, setEditcategoryName] = useState('');
  const [editcategorydescription, setEditcategorydescription] = useState('');
  const [selecteditcategory, setSelecteditcategory] = useState(null)


  const [page, setPage] = useState(1); // state for page number
  const [rowsPerPage, setRowsPerPage] = useState(6); // state for number of rows per page

  // Fetch agencies on component mount
 

  const fetchCategories = async () => {
    try {
      const response = await VendorController.getproductcategory()
      console.log('category', response.data.categories)
      setCategories(response.data.categories)
    } catch (error) {
      setSnackbarMessage('An error occurred while fetching agencies');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }

  }

  useEffect(() => {
    fetchCategories();
  }, []);

  // Filter agencies based on search query
 
  const filteredCategories = categories.filter((category) =>
    category.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    category.description.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Handle form submission for adding new agency
  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      name: categorieName,
      description,
    };

    try {
      setLoading(true);
      await EmployeController.addvendorcategory(data)
      setSnackbarMessage('Agency added successfully!');
      setSnackbarSeverity('success');
      fetchCategories();
      setcategorieName('');
      setDescription('');
      setOpenSnackbar(true);
      setOpenDialog(false);
      setLoading(false);
    } catch (error) {
      setSnackbarMessage('An error occurred');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      setLoading(false);
    }
  };

  // Handle editing an agency
  const handleEditSubmit = async (e) => {
    e.preventDefault();


    const categorydata = {
      categoryid: selecteditcategory.id,
      categories_name: editcategoryName,
      description: editcategorydescription
    }

    try {
      setLoading(true);
      const response = await EmployeController.updateCategory(categorydata);
      console.log('categorie edit:::', response.data)
      setSnackbarMessage(response.data.message);
      setSnackbarSeverity('success');
      fetchCategories();
      setOpenSnackbar(true);
      setOpenEditDialog(false);
      setLoading(false);
    } catch (error) {
      const errorMessage = error.response?.data?.message;
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      setLoading(false);

    }
  };

  // Handle deleting an agency
  const handleDelete = async () => {
    try {
      setLoading(true);
      const data = {
        categoryId: selectedCategory.id,
      };
      const response = await EmployeController.destroyCategory(data);
      setSnackbarMessage(response.data.message);
      setSnackbarSeverity('success');
      fetchCategories();
      setOpenSnackbar(true);
      setOpenDeleteDialog(false);
      setLoading(false);
    } catch (error) {
      setSnackbarMessage('An error occurred');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  // Handle page change
  const handleChangePage = (event, value) => {
    setPage(value);
  };

  // Get paginated agencies
  const paginatedCategories = filteredCategories.slice((page - 1) * rowsPerPage, page * rowsPerPage);

  return (
    <Container maxWidth="ml" sx={{ marginTop: '80px' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ padding: '10px' }}>
        <Grid sx={{ flex: 1, maxWidth: '400px', marginRight: '10px' }}>
          <CustomTextField
            label="Search Categories"
            variant="outlined"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            fullWidth
            sx={{ height: '40px' }}
          />
        </Grid>
        <CustomButton sx={{ width: '200px' }} variant="contained" color="primary" onClick={() => setOpenDialog(true)}>
          Add Category
        </CustomButton>
      </Box>


      <Paper sx={{ marginTop: 3, padding: 2,overflow: 'hidden', boxShadow: 'none' }}>
        <TableContainer>
          <Table>
            <TableHead sx={{ background: teelColor }}>
              <TableRow>
                <TableCell sx={{ color: whiteColor }} align="center">Creation Date</TableCell>
                <TableCell sx={{ color: whiteColor }} align="center">Category Name</TableCell>
                <TableCell sx={{ color: whiteColor }} align="center">Category Description</TableCell>
                <TableCell sx={{ color: whiteColor }} align="center"><strong>Action</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedCategories.map((category) => (
                <TableRow key={category.id}>
                  <TableCell align="center">{formatDate(category.created_at)}</TableCell>
                  <TableCell align="center">{category.name}</TableCell>
                  <TableCell align="center">{category.description}</TableCell>

                  <TableCell align="center">
                    <IconButton sx={{ color: lightOrangeColor }} onClick={() => { setSelecteditcategory(category); setEditcategoryName(category.name); setEditcategorydescription(category.description); setOpenEditDialog(true); }}>
                      <EditIcon />
                    </IconButton>
                    <IconButton sx={{ color: redColor }} onClick={() => { setselectedCategory(category); setOpenDeleteDialog(true); }}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Pagination */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
          <Pagination
            count={Math.ceil(filteredCategories.length / rowsPerPage)}
            page={page}
            onChange={handleChangePage}
            color="primary"
          />
        </Box>

      </Paper>

      {/* Dialog for adding agency */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} TransitionComponent={Transition}>
        <DialogTitle>Add a New Category</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{marginTop:"10px"}}>
                <CustomTextField
                  fullWidth
                  label="Categorie Name"
                  value={categorieName}
                  onChange={(e) => setcategorieName(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  fullWidth
                  label="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                />
              </Grid>
              <DialogActions>
                <CustomCancelButton onClick={() => setOpenDialog(false)}>Cancel</CustomCancelButton>
                <CustomButton variant="contained" color="primary" type="submit">
                  {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Add Category'}
                </CustomButton>
              </DialogActions>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>

      {/* Dialog for editing agency */}
      <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)} TransitionComponent={Transition}>
      <DialogTitle>Edit the Category</DialogTitle>
        <DialogContent>
          <form onSubmit={handleEditSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomTextField
                  fullWidth
                  label="categorie Name"
                  value={editcategoryName}
                  onChange={(e) => setEditcategoryName(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  fullWidth
                  label="category Description"
                  value={editcategorydescription}
                  onChange={(e) => setEditcategorydescription(e.target.value)}
                  required
                />
              </Grid>

              <DialogActions>
                <CustomCancelButton onClick={() => setOpenEditDialog(false)}>Cancel</CustomCancelButton>
                <CustomButton variant="contained" color="primary" type="submit">
                  {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Save Changes'}
                </CustomButton>
              </DialogActions>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>

      {/* Dialog for deleting agency */}
      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)} TransitionComponent={DeleteTransition}>
        <DialogTitle>Delete Agency</DialogTitle>
        <DialogContent>
          <Typography variant="body1">Are you sure you want to delete this agency?</Typography>
        </DialogContent>
        <DialogActions>
          <CustomCancelButton onClick={() => setOpenDeleteDialog(false)} >Cancel</CustomCancelButton>
          <CustomDeleteButton onClick={handleDelete} >
          {loading ? <CircularProgress size={24} sx={{ color: teelColor }} /> : 'Delete'}
          </CustomDeleteButton>
        </DialogActions>
      </Dialog>

      {/* Snackbar for success/error */}
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
        <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AddCategory;



