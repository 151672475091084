// src/components/CustomCancelButton.js
import React from 'react';
import { Button } from '@mui/material';
import { teelColor, grayColor } from '../../utils/config'; // Assurez-vous que le chemin est correct

const CustomCancelButton = ({ onClick, children, ...props }) => {
  return (
    <Button
      onClick={onClick}
      {...props}
      sx={{
        backgroundColor: 'transparent', // Fond transparent pour le bouton d'annulation
        color: grayColor, // Couleur du texte
        border: `1px solid ${teelColor}`, // Bordure de la même couleur
        '&:hover': {
          backgroundColor: grayColor, // Fond lorsque le bouton est survolé
          color: '#fff', // Couleur du texte lorsque survolé
        },
        padding: '8px 16px', // Padding pour le bouton
      }}
    >
      {children}
    </Button>
  );
};

export default CustomCancelButton;
