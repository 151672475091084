// src/components/CustomSelect.js
import React from 'react';
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { teelColor } from '../../utils/config'; // Assurez-vous que le chemin est correct

const CustomSelect = ({ value, onChange, label, options, ...props }) => {
  return (
    <FormControl fullWidth>
      <InputLabel
        sx={{
          color: teelColor, // Couleur du label
          '&.Mui-focused': {
            color: teelColor, // Couleur du label lorsqu'il est focalisé
          },
        }}
      >
        {label}
      </InputLabel>
      <Select
        value={value}
        onChange={onChange}
        label={label}
        {...props}
        sx={{
          '& .MuiSelect-select': {
            color: teelColor, // Couleur du texte de l'input
          },
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: teelColor, // Couleur de la bordure lorsque le champ est focalisé
            },
          },
          '&:before': {
            borderBottom: `1px solid ${teelColor}`, // Couleur de la bordure avant
          },
          '&:after': {
            borderBottom: `2px solid ${teelColor}`, // Couleur de la bordure après
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomSelect;
