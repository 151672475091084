import React, { useState, useEffect } from 'react';
import {
    Table, TableRow, TableHead, TableBody,
    TableCell, TableContainer, TablePagination, Paper,
    Dialog, DialogActions, DialogContent, DialogTitle,
    Typography, Grid, CircularProgress
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import { tealColor, teelColor, whiteColor } from '../../utils/config';
import EmployeController from '../../Controllers/EmployeeController';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const CustomerPaiementModeData = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const AdmingetCutomerPaiement = async () => {
        try {
            const responsepaiement = await EmployeController.AdmingetCutomerPaiement();
            console.log('Response Data:', responsepaiement.data.paiementdata);
            setData(responsepaiement.data.paiementdata);
            setLoading(false);
        } catch (error) {
            console.error('Erreur lors du chargement des commandes :', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        AdmingetCutomerPaiement();
    }, []);

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDialogOpen = (row) => {
        setSelectedRow(row);
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setSelectedRow(null);
    };

    const formatDate = (dateString) => {
        const options = {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
    
        };
        return new Date(dateString).toLocaleDateString('en-US', options);
      };

    return (
        <Paper>
            <Typography variant="h6" align="center" color={tealColor} sx={{ p: 2 }}>
                Customer Payment Modes
            </Typography>
            {loading ? (
                <Grid container justifyContent="center" sx={{ p: 3 }}>
                    <CircularProgress />
                </Grid>
            ) : (
                <TableContainer>
                    <Table>
                        <TableHead sx={{background:teelColor}}>
                            <TableRow>
                                <TableCell sx={{color:whiteColor}}>Creation date</TableCell>
                                <TableCell sx={{color:whiteColor}}>Total Amount</TableCell>
                                <TableCell sx={{color:whiteColor}}>Month 1</TableCell>
                                <TableCell sx={{color:whiteColor}}>Month 2</TableCell>
                                <TableCell sx={{color:whiteColor}}>Month 3</TableCell>
                                <TableCell sx={{color:whiteColor}}>Month 4</TableCell>
                                <TableCell sx={{color:whiteColor}}>Month 5</TableCell>
                                <TableCell sx={{color:whiteColor}}>Month 6</TableCell>
                                <TableCell sx={{color:whiteColor}}>User Name</TableCell>
                                {/* <TableCell sx={{color:whiteColor}}>Actions</TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell>{formatDate(row.created_at)}</TableCell>
                                    <TableCell>{row.total_amount}</TableCell>
                                    <TableCell>{row.month_1}</TableCell>
                                    <TableCell>{row.month_2}</TableCell>
                                    <TableCell>{row.month_3}</TableCell>
                                    <TableCell>{row.month_4}</TableCell>
                                    <TableCell>{row.month_5}</TableCell>
                                    <TableCell>{row.month_6}</TableCell>
                                    <TableCell>{row.user_firstname} {row.user_lastname}</TableCell>
                                    {/* <TableCell>
                                        <IconButton onClick={() => handleDialogOpen(row)}>
                                            <InfoIcon />
                                        </IconButton>
                                    </TableCell> */}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        component="div"
                        count={data.length}
                        page={page}
                        onPageChange={handlePageChange}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleRowsPerPageChange}
                    />
                </TableContainer>
            )}

            <Dialog
                open={dialogOpen}
                TransitionComponent={Transition}
                onClose={handleDialogClose}
                aria-labelledby="payment-details-dialog"
            >
                <DialogTitle id="payment-details-dialog">Payment Details</DialogTitle>
                <DialogContent>
                    {selectedRow && (
                        <>
                            <Typography variant="body1">ID: {selectedRow.id}</Typography>
                            <Typography variant="body1">Total Amount: {selectedRow.total_amount}</Typography>
                            <Typography variant="body1">Period: {selectedRow.period}</Typography>
                            <Typography variant="body1">Month 1: {selectedRow.month_1}</Typography>
                            <Typography variant="body1">Month 2: {selectedRow.month_2}</Typography>
                            <Typography variant="body1">Month 3: {selectedRow.month_3}</Typography>
                            <Typography variant="body1">User Email: {selectedRow.user_email}</Typography>
                            <Typography variant="body1">User Mobile: {selectedRow.user_mobile}</Typography>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>
    );
};

export default CustomerPaiementModeData;
