import React, { useEffect, useState } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import MyAppBar from './Components/AppBar';
import Sidebar from './Components/Sidebar';
import LoginPage from './pages/login/LoginPage';
import AddProduct from './views/pages/AddProduct';
import Dashboard from './views/pages/homePage';
import ViewOrders from './views/pages/ViewOrders';
import ViewProducts from './views/pages/ViewProducts';
import LinearProgress from '@mui/material/LinearProgress';
import UserEmployer from './views/pages/employee/listeEmployeer';
import UserVendor from './views/pages/Vendors/vendorListe';
import AddEmployeeForm from './views/pages/addvendorAndEmployee';
import VendorproductListe from './views/pages/Vendors/vendorproductliste';
import CustomerorderListe from './views/pages/Admin/customerordersList';
import { teelColor,grayColor,whiteColor } from './utils/config';
import PaiementListe from './views/pages/Admin/paiementListe';
import { CircularProgress } from '@mui/material';

import AddAgency from './views/pages/AddOthersviews/indexAgence';
import AddCategory from './views/pages/AddOthersviews/indexCategory';
import UserProfile from './views/pages/AddOthersviews/editeprofile';

import { keyframes } from '@emotion/react';

// Animation pour l'image de chargement
const rotate = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;


function App() {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [loading, setLoading] = useState(true); // État de chargement

  // Vérifie si un token existe
  const hasToken = () => {
    const token = localStorage.getItem('tokenlpa');
    console.log('voila le token', token);
    return token;
  };

  const toggleSidebar = () => {
    setSidebarOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setSidebarOpen(false);
      } else {
        setSidebarOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Simule le chargement pendant 5 secondes
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false); // Fin du chargement après 5 secondes
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  // Si pas de token, afficher uniquement la page de login
  if (!hasToken()) {
    return (
      <Router>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </Router>
    );
  }



  if (loading) {
    return (

      loading && (
        <div style={{ width: '100%',background:"white" }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100vh',
              width: '100%',
              backgroundColor: '#ffff', // Optionnel: ajout d'un fond transparent pour plus de contraste
            }}
          >
            {/* CircularProgress avec animation */}
            <CircularProgress
              size={100} // Taille du cercle
              sx={{color:teelColor, animation: `${rotate} 2s linear infinite` }} // Applique l'animation de rotation
             // Vous pouvez changer la couleur ici
            />
          </div>
        </div>
      )
    );
  }

  // Si token présent, afficher l'application complète
  return (
    <Router>
      <div style={{ display: 'flex' }}>
        <MyAppBar onMenuClick={toggleSidebar} />
        <Sidebar isOpen={sidebarOpen} />
        <div
          style={{
            marginLeft: sidebarOpen ? (window.innerWidth >= 600 ? 250 : 0) : 0,
            padding: '25px',
            width: '100%',
          }}
        >
          <Routes>
            <Route path="/add-product" element={<AddProduct />} />
            <Route path="/products" element={<ViewProducts />} />
            <Route path="/orders" element={<ViewOrders />} />
            <Route path="/employListe" element={<UserEmployer />} />
            <Route path="/UserVendorListe" element={<UserVendor />} />
            <Route path="/addemployvendor" element={<AddEmployeeForm />} />
            <Route path="/vendorproductListe" element={<VendorproductListe/>} />
            <Route path="/Customerorderdata" element={<CustomerorderListe/>}/>
            <Route path="/PaiementListe" element={<PaiementListe/>}/>
            <Route path="/Addagency" element={<AddAgency/>}/>
            <Route path="AddCategory" element={<AddCategory/>} />
            <Route path="UserProfile" element={<UserProfile/>}/>
            <Route path="/index" element={<Dashboard />} />
            <Route path="/" element={<Navigate to="/index" />} />
            <Route path="*" element={<Navigate to="/index" />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;

