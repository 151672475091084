// ** MUI Imports
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// ** Utils Imports
import { teelColor } from '../../../utils/config';
import CustomerPaiementModeData from '../../tables/CustomerPaiementmodeListe';
const PaiementListe = () => {
  return (

    <Grid container spacing={6}>
      <Grid item xs={12}>
        
      </Grid>
      <Grid item xs={12}>
        <CustomerPaiementModeData></CustomerPaiementModeData>
      </Grid>
    </Grid>

    
  );
};

export default PaiementListe;
