import React, { useState, useEffect } from 'react';
import {
    Table, TableRow, TableHead, TableBody,
    TableCell, TableContainer, TablePagination, Paper,
    Dialog, DialogActions, DialogContent, DialogTitle,
    Typography, Grid, Alert, CircularProgress, Box, Container
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Slide from '@mui/material/Slide';
import { teelColor, whiteColor, greenColor, orangeColor, blueColor, redColor } from '../../utils/config';
import VendorController from '../../Controllers/VendorControllers';
import { urlImage } from '../../utils/config';
import CustomCancelButton from '../../Components/formComponent/CustomCancelButton';
import CustomButton from '../../Components/formComponent/CustomButton';
import Pagination from '@mui/material/Pagination';

// Transition pour le dialogue
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const CustomersorderData = () => {
    const [page, setPage] = useState(1); // Page actuelle (commence à 1 pour correspondre à la pagination de MUI)
    const [rowsPerPage, setRowsPerPage] = useState(6);
    const [openDialog, setOpenDialog] = useState(false);
    const [productRows, setProductRows] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [showError, setShowError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [loading, setLoading] = useState(false);


    const fetchCustomerOrderData = async () => {
        try {
            const response = await VendorController.getallvendororder();
            setProductRows(response.data.data); // appel de l'API
            console.log(response.data.data);
        } catch (error) {
            console.error('Erreur lors de la récupération des données :', error);
        }
    };
    // Récupération des données des commandes
    useEffect(() => {

        fetchCustomerOrderData();
    }, []);

    const validatedorder = async (ordersId, orderId) => {
        try {
            setLoading(true);
            const Data = {
                userid: ordersId,
                orderId: orderId
            }
            const response = await VendorController.rhvalidatedcustomerord(Data)
            console.log('reponsessss', response)
            setSuccess(response.data.message);
            fetchCustomerOrderData();
            setShowSuccess(true);
            setTimeout(() => setShowSuccess(false), 3000);
            setLoading(false);
            handleCloseDialog()

        } catch (error) {
            setLoading(false);
            handleCloseDialog()
            setError(error.response?.data?.message);
            setShowError(true);
            setTimeout(() => setShowError(false), 3000);
            console.error('Erreur lors de la récupération des données :', error);
        }
    }

    // Gestion de la pagination
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Afficher le modal avec les détails du produit
    const handleOpenDialog = (product) => {
        setSelectedProduct(product); // Sélectionner le produit
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedProduct(null); // Réinitialiser le produit sélectionné
    };

    const formatDate = (dateString) => {
        const options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        };
        return new Date(dateString).toLocaleDateString('en-US', options);
    };

    // Calculer les lignes pour la page actuelle
    const displayedRows = productRows.slice((page - 1) * rowsPerPage, page * rowsPerPage);


    return (
        <Container maxWidth="ml" sx={{ marginTop: '10px' }}>
              <Typography variant="h4" sx={{ textAlign: 'center', color: teelColor, mb: 2 }}>
                    List Orders
                </Typography>
            <Paper sx={{ marginTop: 3, padding: 2,overflow: 'hidden', boxShadow: 'none' }}>



              
                {showError && <Alert severity="error">{error}</Alert>}
                {showSuccess && <Alert severity="success">{success}</Alert>}
                <TableContainer sx={{ maxHeight: 1000 }}>
                    <Table sx={{ minWidth: 550 }}>
                        <TableHead sx={{ background: teelColor, color: whiteColor }}>
                            <TableRow>
                                <TableCell sx={{ color: whiteColor }} align="center">Creation date</TableCell>
                                <TableCell sx={{ color: whiteColor }} align="center">Customer Name</TableCell>
                                <TableCell sx={{ color: whiteColor }} align="center">Total</TableCell>
                                <TableCell sx={{ color: whiteColor }} align="center">Order Status</TableCell>
                                <TableCell sx={{ color: whiteColor }} align="center">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {displayedRows.map((row) => (
                                <TableRow key={row.order_id}>
                                    <TableCell align="center">{formatDate(row.order_created_at)}</TableCell>
                                    <TableCell align="center">{row.customer_name}</TableCell>
                                    <TableCell align='center'>{row.order_total} $</TableCell>
                                    <TableCell align="center"
                                        style={{
                                            color: row.order_status_text === 'Pending' ? orangeColor :
                                                row.order_status_text === 'Processing' ? blueColor :
                                                    row.order_status_text === 'Validated' ? greenColor :
                                                        row.order_status_text === 'Delivered' ? teelColor :
                                                            row.order_status_text === 'Cancelled' ? redColor :
                                                                'gray'  // default color for unknown status
                                        }}
                                    >
                                        {row.order_status_text}</TableCell>
                                    <TableCell align="center">
                                        <IconButton sx={{ color: teelColor }} aria-label="info" onClick={() => handleOpenDialog(row)}>
                                            <InfoIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>



                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                    <Pagination
                        count={Math.ceil(productRows.length / rowsPerPage)}
                        page={page}
                        onChange={handleChangePage}
                        color="primary"
                    />
                </Box>

                {/* Dialogue de détail de la commande */}
                <Dialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    TransitionComponent={Transition}
                    fullWidth
                    maxWidth="md"
                >
                    <DialogTitle>Order Details</DialogTitle>
                    <DialogContent>
                        {selectedProduct && (
                            <Grid container spacing={2}>
                                <Grid item xs={12}>


                                    <Grid container justifyContent="space-between" alignItems="center" style={{ marginBottom: 20 }}>
                                        {/* Section gauche avec les trois premiers éléments */}
                                        <Grid item xs={6}>
                                            <Grid container direction="column" spacing={2}>
                                                <Grid item>
                                                    <Typography variant="subtitle1">
                                                        Order ID: <a href="#" style={{ color: '#1a73e8' }}>#{selectedProduct.order_id}</a>
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="subtitle1">
                                                        Order Date: <a href="#" style={{ color: '#1a73e8' }}>
                                                            {new Date(selectedProduct.order_created_at).toLocaleDateString()} at {new Date(selectedProduct.order_created_at).toLocaleTimeString()}
                                                        </a>
                                                    </Typography>
                                                </Grid>

                                            </Grid>
                                        </Grid>


                                        {/* Section droite avec les deux autres éléments */}
                                        <Grid item>
                                            <Grid container direction="column" spacing={2} alignItems="flex-end">
                                                <Grid item>
                                                    <Grid item>
                                                        <Typography variant="subtitle1">
                                                            {selectedProduct.customer_firstaname} {selectedProduct.customer_lastname}
                                                        </Typography>
                                                    </Grid>
                                                    <Typography variant="subtitle1">
                                                        {selectedProduct.customer_mobile}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="subtitle1">
                                                        <a href={`mailto:${selectedProduct.customer_email}`} style={{ color: '#1a73e8' }}>
                                                            {selectedProduct.customer_email}
                                                        </a>
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                    </Grid>

                                    <Table sx={{ mt: 2, border: '1px solid #ddd' }}>
                                        <TableHead>
                                            <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                                                <TableCell align="center">Product</TableCell>
                                                <TableCell align="center">Product Name</TableCell>
                                                <TableCell align="center">Quantity</TableCell>
                                                <TableCell align="center">Unit Price</TableCell>
                                                <TableCell align="center">Total Price</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {selectedProduct.products.map((product, index) => (
                                                <TableRow key={index}>
                                                    <TableCell align="center">
                                                        <img
                                                            src={product.product1 ? `${urlImage}${product.product1}` : '/placeholder.jpg'}
                                                            alt="Product"
                                                            style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '4px' }}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="center">{product.product_name}</TableCell>
                                                    <TableCell align="center">${product.produ_quanty}</TableCell>
                                                    <TableCell align="center">${product.product_price.toFixed(2)}</TableCell>
                                                    <TableCell align="center">
                                                        ${(product.product_price * product.produ_quanty).toFixed(2)}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                            <TableRow>
                                                <TableCell rowSpan={4} sx={{ borderBottom: "none" }} />
                                                <TableCell colSpan={3} align="right" sx={{ fontWeight: 'bold', borderBottom: "none" }}>Sub Total:</TableCell>
                                                <TableCell align="right" sx={{ borderBottom: "none" }}>${selectedProduct.order_total.toFixed(2)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={3} align="right" sx={{ fontWeight: 'bold', borderBottom: "none" }}>Shipping:</TableCell>
                                                <TableCell align="right" sx={{ borderBottom: "none" }}>Free</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={3} align="right" sx={{ fontWeight: 'bold', borderBottom: "none" }}>Total:</TableCell>
                                                <TableCell align="right" sx={{ borderBottom: "none" }}>${selectedProduct.order_total.toFixed(2)}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>


                                </Grid>
                            </Grid>
                        )}
                    </DialogContent>
                    <DialogActions>
                        {selectedProduct && selectedProduct.order_status_text === 'Validated' ? (
                            ''
                        ) : (
                            <CustomButton
                                sx={{ width: 200 }}
                                onClick={() => validatedorder(selectedProduct.order_user_id, selectedProduct.order_id)}
                                color="primary"
                            >
                                {loading ? <CircularProgress size={24} color="inherit" /> : 'Validate the order'}
                            </CustomButton>
                        )}

                        <CustomCancelButton onClick={handleCloseDialog} color="primary">Close</CustomCancelButton>
                    </DialogActions>
                </Dialog>

            </Paper>
        </Container>
    );
};

export default CustomersorderData;






// import React, { useState, useEffect } from 'react';
// import {
//     Table, TableRow, TableHead, TableBody,
//     TableCell, TableContainer, TablePagination, Paper,
//     Dialog, DialogActions, DialogContent, DialogTitle,
//     Typography, Grid, Alert, CircularProgress
// } from '@mui/material';
// import IconButton from '@mui/material/IconButton';
// import InfoIcon from '@mui/icons-material/Info';
// import Slide from '@mui/material/Slide';
// import { teelColor, whiteColor, greenColor, orangeColor, blueColor, redColor } from '../../utils/config';
// import VendorController from '../../Controllers/VendorControllers';
// import { urlImage } from '../../utils/config';
// import CustomCancelButton from '../../Components/formComponent/CustomCancelButton';
// import CustomButton from '../../Components/formComponent/CustomButton';

// // Transition pour le dialogue
// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="down" ref={ref} {...props} />;
// });

// const CustomersorderData = () => {
//     const [page, setPage] = useState(0);
//     const [rowsPerPage, setRowsPerPage] = useState(5);
//     const [openDialog, setOpenDialog] = useState(false);
//     const [productRows, setProductRows] = useState([]);
//     const [selectedProduct, setSelectedProduct] = useState(null);
//     const [error, setError] = useState('');
//     const [success, setSuccess] = useState('');
//     const [showError, setShowError] = useState(false);
//     const [showSuccess, setShowSuccess] = useState(false);
//     const [loading, setLoading] = useState(false);

//     // Récupération des données des commandes
//     useEffect(() => {
//         const fetchCustomerOrderData = async () => {
//             try {
//                 const response = await VendorController.getallvendororder();
//                 setProductRows(response.data.data); // appel de l'API
//                 console.log(response.data.data);
//             } catch (error) {
//                 console.error('Erreur lors de la récupération des données :', error);
//             }
//         };
//         fetchCustomerOrderData();
//     }, []);

//     const validatedorder = async (ordersId, orderId) => {
//         try {
//             setLoading(true);
//             const Data = {
//                 userid: ordersId,
//                 orderId: orderId
//             }
//             const response = await VendorController.rhvalidatedcustomerord(Data)
//             console.log('reponsessss', response)
//             setSuccess(response.data.message);
//             setShowSuccess(true);
//             setTimeout(() => setShowSuccess(false), 3000);
//             setLoading(false);
//             handleCloseDialog()

//         } catch (error) {
//             setLoading(false);
//             handleCloseDialog()
//             setError(error.response?.data?.message);
//             setShowError(true);
//             setTimeout(() => setShowError(false), 3000);
//             console.error('Erreur lors de la récupération des données :', error);
//         }
//     }

//     // Gestion de la pagination
//     const handleChangePage = (event, newPage) => {
//         setPage(newPage);
//     };

//     const handleChangeRowsPerPage = (event) => {
//         setRowsPerPage(parseInt(event.target.value, 10));
//         setPage(0);
//     };

//     // Afficher le modal avec les détails du produit
//     const handleOpenDialog = (product) => {
//         setSelectedProduct(product); // Sélectionner le produit
//         setOpenDialog(true);
//     };

//     const handleCloseDialog = () => {
//         setOpenDialog(false);
//         setSelectedProduct(null); // Réinitialiser le produit sélectionné
//     };

//     const formatDate = (dateString) => {
//         const options = {
//             year: 'numeric',
//             month: 'numeric',
//             day: 'numeric',
//             hour: '2-digit',
//             minute: '2-digit',
//             second: '2-digit',
//         };
//         return new Date(dateString).toLocaleDateString('en-US', options);
//     };

//     return (
//         <Paper sx={{ width: '100%', overflow: 'hidden' }}>
//             {showError && <Alert severity="error">{error}</Alert>}
//             {showSuccess && <Alert severity="success">{success}</Alert>}

//             <Typography variant="h4" sx={{ textAlign: 'center', color: teelColor, mb: 2 }}>
//                 List Orders
//             </Typography>
//             <TableContainer sx={{ maxHeight: 500 }}>
//                 <Table sx={{ minWidth: 550 }}>
//                     <TableHead sx={{ background: teelColor, color: whiteColor }}>
//                         <TableRow>
//                             <TableCell sx={{ color: whiteColor }} align="center">Creation date</TableCell>
//                             <TableCell sx={{ color: whiteColor }} align="center">Customer Name</TableCell>
//                             <TableCell sx={{ color: whiteColor }} align="center">Total</TableCell>
//                             <TableCell sx={{ color: whiteColor }} align="center">Order Status</TableCell>
//                             <TableCell sx={{ color: whiteColor }} align="center">Action</TableCell>
//                         </TableRow>
//                     </TableHead>
//                     <TableBody>
//                         {productRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
//                             <TableRow key={row.order_id}>
//                                 <TableCell align="center">{formatDate(row.order_created_at)}</TableCell>
//                                 <TableCell align="center">{row.customer_name}</TableCell>
//                                 <TableCell align='center'>{row.order_total} $</TableCell>
//                                 <TableCell align="center"
//                                     style={{
//                                         color: row.order_status_text === 'Pending' ? orangeColor :
//                                             row.order_status_text === 'Processing' ? blueColor :
//                                                 row.order_status_text === 'Validated' ? greenColor :
//                                                     row.order_status_text === 'Delivered' ? teelColor :
//                                                         row.order_status_text === 'Cancelled' ? redColor :
//                                                             'gray'  // default color for unknown status
//                                     }}
//                                 >
//                                     {row.order_status_text}</TableCell>
//                                 <TableCell align="center">
//                                     <IconButton sx={{ color: teelColor }} aria-label="info" onClick={() => handleOpenDialog(row)}>
//                                         <InfoIcon />
//                                     </IconButton>
//                                 </TableCell>
//                             </TableRow>
//                         ))}
//                     </TableBody>
//                 </Table>
//             </TableContainer>

//             <TablePagination
//                 rowsPerPageOptions={[5, 10, 25]}
//                 component="div"
//                 count={productRows.length}
//                 rowsPerPage={rowsPerPage}
//                 page={page}
//                 onPageChange={handleChangePage}
//                 onRowsPerPageChange={handleChangeRowsPerPage}
//             />

//             {/* Dialogue de détail de la commande */}
//             <Dialog
//                 open={openDialog}
//                 onClose={handleCloseDialog}
//                 TransitionComponent={Transition}
//                 fullWidth
//                 maxWidth="md"
//             >
//                 <DialogTitle>Order Details</DialogTitle>
//                 <DialogContent>
//                     {selectedProduct && (
//                         <Grid container spacing={2}>
//                             <Grid item xs={12}>


//                                 <Grid container justifyContent="space-between" alignItems="center" style={{ marginBottom: 20 }}>
//                                     {/* Section gauche avec les trois premiers éléments */}
//                                     <Grid item xs={6}>
//                                         <Grid container direction="column" spacing={2}>
//                                             <Grid item>
//                                                 <Typography variant="subtitle1">
//                                                     Order ID: <a href="#" style={{ color: '#1a73e8' }}>#{selectedProduct.order_id}</a>
//                                                 </Typography>
//                                             </Grid>
//                                             <Grid item>
//                                                 <Typography variant="subtitle1">
//                                                     Order Date: <a href="#" style={{ color: '#1a73e8' }}>
//                                                         {new Date(selectedProduct.order_created_at).toLocaleDateString()} at {new Date(selectedProduct.order_created_at).toLocaleTimeString()}
//                                                     </a>
//                                                 </Typography>
//                                             </Grid>

//                                         </Grid>
//                                     </Grid>


//                                     {/* Section droite avec les deux autres éléments */}
//                                     <Grid item>
//                                         <Grid container direction="column" spacing={2} alignItems="flex-end">
//                                             <Grid item>
//                                                 <Grid item>
//                                                     <Typography variant="subtitle1">
//                                                         {selectedProduct.customer_firstaname} {selectedProduct.customer_lastname}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Typography variant="subtitle1">
//                                                     {selectedProduct.customer_mobile}
//                                                 </Typography>
//                                             </Grid>
//                                             <Grid item>
//                                                 <Typography variant="subtitle1">
//                                                     <a href={`mailto:${selectedProduct.customer_email}`} style={{ color: '#1a73e8' }}>
//                                                         {selectedProduct.customer_email}
//                                                     </a>
//                                                 </Typography>
//                                             </Grid>
//                                         </Grid>
//                                     </Grid>

//                                 </Grid>

//                                 <Table sx={{ mt: 2, border: '1px solid #ddd' }}>
//                                     <TableHead>
//                                         <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
//                                             <TableCell align="center">Product</TableCell>
//                                             <TableCell align="center">Product Name</TableCell>
//                                             <TableCell align="center">Quantity</TableCell>
//                                             <TableCell align="center">Unit Price</TableCell>
//                                             <TableCell align="center">Total Price</TableCell>
//                                         </TableRow>
//                                     </TableHead>
//                                     <TableBody>
//                                         {selectedProduct.products.map((product, index) => (
//                                             <TableRow key={index}>
//                                                 <TableCell align="center">
//                                                     <img
//                                                         src={product.product1 ? `${urlImage}${product.product1}` : '/placeholder.jpg'}
//                                                         alt="Product"
//                                                         style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '4px' }}
//                                                     />
//                                                 </TableCell>
//                                                 <TableCell align="center">{product.product_name}</TableCell>
//                                                 <TableCell align="center">${product.produ_quanty}</TableCell>
//                                                 <TableCell align="center">${product.product_price.toFixed(2)}</TableCell>
//                                                 <TableCell align="center">
//                                                     ${(product.product_price * product.produ_quanty).toFixed(2)}
//                                                 </TableCell>
//                                             </TableRow>
//                                         ))}
//                                         <TableRow>
//                                             <TableCell rowSpan={4} sx={{ borderBottom: "none" }} />
//                                             <TableCell colSpan={3} align="right" sx={{ fontWeight: 'bold', borderBottom: "none" }}>Sub Total:</TableCell>
//                                             <TableCell align="right" sx={{ borderBottom: "none" }}>${selectedProduct.order_total.toFixed(2)}</TableCell>
//                                         </TableRow>
//                                         <TableRow>
//                                             <TableCell colSpan={3} align="right" sx={{ fontWeight: 'bold', borderBottom: "none" }}>Shipping:</TableCell>
//                                             <TableCell align="right" sx={{ borderBottom: "none" }}>Free</TableCell>
//                                         </TableRow>
//                                         <TableRow>
//                                             <TableCell colSpan={3} align="right" sx={{ fontWeight: 'bold', borderBottom: "none" }}>Total:</TableCell>
//                                             <TableCell align="right" sx={{ borderBottom: "none" }}>${selectedProduct.order_total.toFixed(2)}</TableCell>
//                                         </TableRow>
//                                     </TableBody>
//                                 </Table>


//                             </Grid>
//                         </Grid>
//                     )}
//                 </DialogContent>
//                 <DialogActions>
//                     {selectedProduct && selectedProduct.order_status_text === 'Validated' ? (
//                         ''
//                     ) : (
//                         <CustomButton
//                             sx={{ width: 200 }}
//                             onClick={() => validatedorder(selectedProduct.order_user_id, selectedProduct.order_id)}
//                             color="primary"
//                         >
//                             {loading ? <CircularProgress size={24} color="inherit" /> : 'Validate the order'}
//                         </CustomButton>
//                     )}

//                     <CustomCancelButton onClick={handleCloseDialog} color="primary">Close</CustomCancelButton>
//                 </DialogActions>
//             </Dialog>

//         </Paper>
//     );
// };

// export default CustomersorderData;


