module.exports = {
    footerText: `Admin © ${new Date().getFullYear()}`,
    greenColor: '#079905',
    redColor: '#FC1C54',
    orangeColor: '#F85515',
    enattenteColor: '#616BFA',
    blueColor: '#1E90FF',
    teelColor: '#00008B',
    whiteColor: '#FFFFFF',
    blackColor: '#000000',
    grayColor: '#808080',
    lightGrayColor: '#C0C0C0',
    darkGrayColor: '#696969',
    lightBlueColor: '#ADD8E6',
    lightGreenColor: '#90EE90',
    lightRedColor: '#FFA07A',
    lightOrangeColor: '#FFA07A',
    lightYellowColor: '#FFFFF0',
    colorteel:'#00008B',
    sibarbg:'f6f5f5',
    iconColor:"#2727f0",
    dashcolor:"#4f4ff7",
  
  
    //  pour les url de des image
    urlImage: 'https://lpa.softsolutionsdev.com/app/public/',
    urlAvatar:'https://lpa.softsolutionsdev.com/app/',
    baseUrl:'https://lpa.softsolutionsdev.com/'
  };
  