import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery
} from '@mui/material';
import SidebarNavigation from './navItems';
import { sibarbg, teelColor } from '../utils/config';

const Sidebar = ({ isOpen }) => {
  const isLargeScreen = useMediaQuery('(min-width:576px)');
  const [selectedItem, setSelectedItem] = useState(null);

  const navItems = SidebarNavigation();

  return (
    <Box
      className="sidebar"
      sx={{
        width: isLargeScreen ? 250 : (isOpen ? '70%' : 0), // Ajuste la largeur en fonction de l'état isOpen
        bgcolor: teelColor,
        height: '120%',
        padding: '0px',
        position: isLargeScreen ? 'fixed' : 'absolute', // Utilise 'fixed' pour les grands écrans, 'absolute' pour les petits
        top: 40, // Pour éviter les chevauchements avec l'AppBar
        left: 0,
        overflowX: 'hidden',
        transition: 'width 0.3s ease', // Transition fluide pour la largeur
        zIndex: 1000, // Pour s'assurer que la sidebar est au-dessus des autres éléments
      }}
    >
      {/* <Typography variant="h6" sx={{ marginBottom: '16px' }}>
        Dashboard LPA
      </Typography> */}
      <List sx={{ marginTop: 2 }}>
        {navItems.map((item, index) => (
          <div key={index}>
            {item.sectionTitle && (
              <Typography variant="h6" sx={{ marginBottom: '16px', fontWeight: 'bold',color:"#ffff"}}>
                {item.sectionTitle}
              </Typography>
            )}
            {!item.sectionTitle && (
              <ListItem
                component={Link}
                to={item.path}
                onClick={() => setSelectedItem(item.title)} // Met à jour l'élément sélectionné au clic
                sx={{
                  padding: '20px 5px',
                  bgcolor: selectedItem === item.title ? teelColor : 'transparent', // Change le fond si l'élément est sélectionné
                  color: selectedItem === item.title ? 'white' : '#ffff', // Change la couleur du texte
                  transition: 'background-color 0.3s ease',
                  borderRadius: '8px',
                  width: '100%',
                  height: '30px',
                  '&:hover': { 
                    bgcolor: selectedItem === item.title ? teelColor : '#e0e0e0',
                  },
                }}
              >
                <ListItemIcon
                  sx={{ color: selectedItem === item.title ? 'white' : 'inherit', }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.title} />
              </ListItem>
            )}
          </div>
        ))}
      </List>
    </Box>
  );
};

export default Sidebar;



// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import {
//   Box,
//   List,
//   ListItem,
//   ListItemIcon,
//   ListItemText,
//   Typography,
//   useMediaQuery
// } from '@mui/material';
// import SidebarNavigation from './navItems';
// import { sibarbg, teelColor } from '../utils/config';

// const Sidebar = ({ isOpen }) => {
//   const isLargeScreen = useMediaQuery('(min-width:600px)');
//   const [selectedItem, setSelectedItem] = useState(null);

//   const navItems = SidebarNavigation();

//   return (
//     <Box
//       className="sidebar"
//       sx={{
//         width: isLargeScreen ? 250 : (isOpen ? '80%' : 0),
//         bgcolor: sibarbg,
//         height: '100vh',
//         padding: '16px',
//         position: isLargeScreen ? 'fixed' : 'absolute',
//         overflowX: 'hidden',
//         transition: 'width 0.3s ease',
//       }}
//     >
//       <Typography variant="h6" sx={{ marginBottom: '16px' }}>
//         Dashboard LPA
//       </Typography>
//       <List sx={{ marginTop: 2 }}>
//         {navItems.map((item, index) => (
//           <div key={index}>
//             {item.sectionTitle && (
//               <Typography variant="h6" sx={{ marginBottom: '16px', fontWeight: 'bold' }}>
//                 {item.sectionTitle}
//               </Typography>
//             )}
//             {!item.sectionTitle && (
//               <ListItem
//                 component={Link}
//                 to={item.path}
//                 onClick={() => setSelectedItem(item.title)} // Met à jour l'élément sélectionné au clic
//                 sx={{
//                   padding: '20px 10px',
//                   bgcolor: selectedItem === item.title ? teelColor : 'transparent', // Change le fond si l'élément est sélectionné
//                   color: selectedItem === item.title ? 'white' : 'inherit', // Change la couleur du texte
//                   transition: 'background-color 0.3s ease',
//                   borderRadius: '8px',
//                   width: '100%',
//                   height: '30px',
//                   '&:hover': { 
//                     bgcolor: selectedItem === item.title ? teelColor : '#e0e0e0',
//                   },
//                 }}
//               >
//                 <ListItemIcon
//                 sx={{ color: selectedItem === item.title ? 'white' : 'inherit', }}
//                 >{item.icon}</ListItemIcon>
//                 <ListItemText primary={item.title} />
//               </ListItem>
//             )}
//           </div>
//         ))}
//       </List>
//     </Box>
//   );
// };

// export default Sidebar;
